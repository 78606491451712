.slif-Container{
  color: #000;
  //position: absolute;
  //top:1.35rem;
  //right: 2.7rem;
  z-index: 10;
  font-size: 0.3rem;
  margin-bottom: 0.2rem;
  //.slif_icon_wrap {
  //  padding-bottom: 0.2rem;
  //}
  .adas-tips{
    box-sizing: border-box;
    width: 0.81rem;
    height: 0.81rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .adas-tips.NORMAL,.adas-tips.WARNING{
    //box-shadow: 0 0 0 .07rem #E70006;
    border: .04rem solid #FF0100;
    box-sizing: border-box;
    //background: #191C2D;
    background: #fff;
    span{
      //color:#fff;
      color:#000;
      font-weight: 500;
      font-size:.32rem;
    }
  }
  /*.adas-tips.RELIEVE{
    box-shadow: 0 0 0 .1rem #000;
    background: #1C1D32;
    span{
      color:#fff;
      font-size:.42rem;
      font-weight: 600;
    }
  }*/
  @keyframes blink{
    50% {
      color: transparent;
    }
  }
  @-webkit-keyframes blink{
    50% {
      color: transparent;
    }
  }

  @keyframes glow{
    50% {
      //box-shadow: 0 0 0 .1rem transparent;
      border: 5px solid transparent;
      background: transparent;
    }
  }
  @-webkit-keyframes glow{
    50% {
      //box-shadow: 0 0 0 .1rem transparent;
      border: 5px solid transparent;
      background: transparent;
    }
  }


  .adas-tips.WARNING{
    animation: glow 1s 10 steps(1);
    -webkit-animation: glow 1s 10 steps(1);
    span{
      animation: blink 1s steps(1);
      -webkit-animation: blink 1s 10 steps(1);
    }
  }
}

.slif-Container-Padding {
  padding-bottom: 0.2rem;
}
