.TLA_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.1479rem;
  background: #383D54;
  width: 100%;
  height: 100%;
  padding: 0.0888rem 0.2367rem;

  img {
    width: .368rem;
    height: .368rem;
  }
}
