.switch-module-container {
    display: grid;
    grid-template-columns: 6.04rem 6.04rem;
    justify-content: space-between;

    .switch-box {
        &:nth-child(n + 3) {
            margin-top: 0.3rem;
        }
        display: flex;
        flex-direction: column;
        .customise-content {
            flex: 1;
        }
    }

    .main-swtich {
        width: 6.04rem;
        height: 3.06rem;
        background-repeat: no-repeat;
        background-size: 100%;
        border-radius: 0.42rem;
        padding: 0.46rem;
    }

    .top-margin {
        margin-top: 0.3rem;
    }
    .customise-title,
    .switch-type {
        font-weight: 600;
        font-size: 0.32rem;
        line-height: 0.45rem;
        color: #495160;
    }
    .switch-title {
        font-weight: 600;
        font-size: 0.26rem;
        line-height: 0.45rem;
        color: rgba(73, 81, 96, 0.6);
    }
    .customist-tip {
        font-weight: 500;
        font-size: 0.25rem;
        line-height: 0.25rem;
        padding-top: 0.8rem;
        text-align: center;
        color: rgba(73, 81, 96, 0.6);
    }
    .customise-content {
        color: #495160;
        background: rgba(255, 255, 255, 0.45);
        margin-top: 0.3rem;
        padding: 0.36rem 0.46rem;
        border-radius: 0.42rem;
        // min-height: 7rem;
        min-height: 3rem;
        width: 6.04rem;
    }
    .is-filter-en {
        min-height: 7rem;
    }
    .is-filter-empty {
        min-height: 3rem;
    }
    .is_disabled {
        opacity: 0.4;
    }
    .disabled {
        opacity: 0.6;
    }
}

.switch-module-container.black,
.Layout-box.black {
    //.switch-toast {
    //    background: #383D54;
    //    .switch-toast-text {
    //        color: #FFFFFF;
    //    }
    //}
    .customise-title,
    .switch-title,
    .switch-type {
        color: #ffffff;
    }
    .customist-tip {
        color: #ffffff;
    }
    .customise-content {
        color: #ffffff;
        background: #383d54;
    }
}
