.nearby-complete.study-status {
    background: #181d30;
    .bg {
        position: absolute;
        right: 0;
        top: 3rem;
        width: 16.69rem;
    }
    &.sr {
        background: linear-gradient(180deg, #dbe6fc 0%, #eaeff6 25.09%);
        i {
            color: #000;
        }
    }
    h3 {
        margin-left: -0.9rem;
    }
    .study-btn {
        left: 0.42rem;
    }
}
