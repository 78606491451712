.exception-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .exception-title {
        font-size: 0.24rem;
        color: #8991a0;
        margin-top: 0.22rem;
    }
}
.work-bench-data-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
        width: 0.35rem;
        height: 0.35rem;
    }
}
.work-bench-data-tool-tag {
    position: absolute;
    bottom: 0.1rem;
    right: 0.1rem;
    font-size: 0.18rem;
}
.work-bench-base {
    .data-collection-tip {
        position: absolute;
        bottom: 0.1rem;
        left: 0.3rem;
        font-size: 0.18rem;
    }
    .data-collection-tip-modal {
        p {
            font-size: 0.24rem;
            padding: 0.15rem;
        }
        .adm-modal-close {
            font-size: 0.3rem;
        }
        .adm-modal-footer {
            display: none;
        }
    }
}
