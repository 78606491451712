.toast-container {
    margin: 0;
    padding: 0;
}

.mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
}

.toast-wrap {
    position: fixed;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(0,0,0,0.8);
    z-index: 10001;
    color: #ffffff;
    border-radius: 0.08rem
    
}