html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

body {
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}
#root {
  width: 100%;
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

ul,li{
  list-style: none;
}
/*.adm-toast-mask .adm-toast-main{
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #37383D !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  !*line-height: 32px !important;*!
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05) !important;
  display: flex !important;
}
.adm-toast-mask .adm-toast-main-text{
  padding: 26px 22px !important;
  border-radius: 15px !important;
}
.adm-toast-mask.left .adm-toast-main:before{
  display: inline-block;
  content:'' !important;
  background: url('assets/map/curPoint.png') no-repeat center !important;
  width: 50px !important;
  height: 50px !important;
  background-size: cover !important;
 !* margin: 0 auto !important;*!
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}
.adm-toast-mask.left .adm-toast-main{
  display: inline-block !important;
  text-align: center;
  !*display: inline-block !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: auto !important;*!
}
.adm-auto-center{
  display: inline-block !important;
  line-height: 50px !important;
}*/
.adm-toast-mask .adm-toast-main{
  padding: .10rem 0 !important;
  background-color: rgba(2, 104, 234, 0.85) !important;
  color: #FFFFFF !important;
  font-weight: 600 !important;
  font-size: .26rem !important;
  white-space: break-spaces;
  left:20% !important;
  top: 70% !important;
  line-height: .4rem !important;
}
.adm-toast-mask .adm-toast-main-text{
  /*padding: 26px 22px !important;*/
  border-radius: 1rem !important;
  /*width: 3.3rem !important;*/
  min-width: 3.5rem !important;
  max-width: 5.4rem !important;
}
.adm-auto-center-content {
  text-align: center !important;
  padding: 0 0.2rem;
}
/*.adm-toast-mask.left .adm-toast-main:before{
  display: block;
  content:'' !important;
  background: url('assets/map/curPoint.png') no-repeat left center !important;
  width: 50px !important;
  height: 50px !important;
  background-size: cover !important;
  margin: 0 auto !important;
  text-align: center;
}
.adm-toast-mask.left .adm-toast-main{
  !*display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;*!

  display: inline-block !important;
  justify-content: flex-start !important;
  align-items: center !important;
  width: auto !important;
}*/
@media screen and (orientation: portrait) {
  /*竖屏 css*/
  /*.Layout-Container {
    flex-direction: column;
  }
  .mapArea {
    width: 100% !important;
    height: 40% !important;
  }
  .mvizArea {
    width: 100% !important;
    height: 60% !important;
  }*/
}
@media screen and (orientation: landscape) {
  /*横屏 css*/
  /*.Layout-Container {
    flex-direction: column;
  }
  .mapArea {
    width: 100% !important;
    height: 40% !important;
  }
  .mvizArea {
    width: 100% !important;
    height: 60% !important;
  }*/
}


