.p-operate-btn {
    display: flex;
    align-items: center;
    border-radius: 0.16rem;
    border: none;
    box-sizing: border-box;
    border-radius: 0.16rem;
    height: 1.22rem;
    padding: 0 0.3rem;
    position: relative;
    outline: none;
}

.p-operate-btn.d::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #2a69e3;
    border: none;
    border-color: #2a69e3;
    border-radius: inherit;
    transform: translate(-50%, -50%);
    opacity: 0;
    content: '';
}

.p-operate-btn.sr.actvie {
    background-color: #2a69e3;
}

.p-operate-btn.sr.disabled {
    opacity: 0.4;
}
.p-operate-btn.active:active {
    box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
}

.p-operate-btn.d.highlight {
    background: #2a69e3;
}

.p-operate-btn.sr {
    background-color: #2a69e3;
    color: #fff;
}

// .p-operate-btn.sr.highlight{
//     // background-image: linear-gradient(#fff, #fff), linear-gradient(#ffffff, #1CADFF);
//     // background-clip: padding-box, border-box;
//     // background-origin: border-box;
//     // border: 0.04rem solid;
//     // border-image: linear-gradient(180deg, #ffffff, #1CADFF);
//     box-shadow: 0px .21rem .24rem #DDE5F1;
//     background: url(../../../../../../assets/parking/d_new/btn_bg.png) 0 0/100% 100% no-repeat;
// }

.p-operate-btn > img {
    // width: 0.rem;
    height: 0.7rem;
    // width: 0.7rem;
    border-radius: 50%;
    margin-right: 0.24rem;
}

.p-operate-btn > span {
    font-size: 0.34rem;
    font-weight: 500;
}

.p-operate-btn.d > span {
    color: #fff;
}

.p-operate-btn.sr > span {
    color: #fff;
}

.p-operate-btn.disabled {
    opacity: 0.4;
}

.p-operate-btn.d.restart {
    background: #2a69e3;
    border: none;
}
.p-operate-btn.d.restart > span {
    color: #fff;
}

.p-operate-btn.sr.restart {
    background: #cbd4e2;
    border: none;
}
.p-operate-btn.sr.active.restart:active {
    box-shadow: 0 0 0.1rem 0.1rem hsla(#1cadff, 30%, 41%, 0.9);
}

.p-operate-btn.restart > span {
    color: #535861;
    font-weight: 500;
}
