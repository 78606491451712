.p-status-info {
    position: absolute;
    bottom: 0.57rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.7rem;
    height: 0;
    color: #606a7e;
}

.p-status-info-wrap {
    height: 0.93rem;
    background: #24283e;
    display: flex;
    align-items: center;
    padding: 0.15rem 0.35rem;
    border-radius: 0.16rem;
    transform: translate(0, -0.99rem);
    gap: 0.7rem;
    color: #fff;
}
