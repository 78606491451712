.guide-list {
    height: 2.24rem;
    background: rgba(255, 255, 255);
    opacity: 0.4;
    border-radius: 28px;
    display: flex;
    // justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    font-size: 0.66rem;
    padding: 0 0.44rem;
    line-height: 0.77rem;
    color: #6a7889;
    margin-top: 0.36rem;
    .r-desc {
        margin-left: 0.3rem;
    }
}
.guide-list.true {
    color: #07a5ff;
    background: #fff;
    font-size: 0.64rem;
    line-height: 0.9rem;
    opacity: 1;
    .l-icon.true {
        flex-shrink: 0;
        position: relative;
        margin: 0;
        width: 1.32rem;
        height: 1.32rem;
        border-radius: 50%;
        // animation: rotate 11s infinite ease-in-out;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            background: conic-gradient(transparent, transparent, #00a2ff, #00a2ff);
            mask: radial-gradient(transparent, transparent 0.58rem, #000 0.585rem, #000);
            animation: change 2s infinite cubic-bezier(0.57, 0.29, 0.49, 0.76);
        }
        .step-title {
            position: absolute;
            font-size: 0.73rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .r-desc {
        color: #07a5ff;
    }
}
.l-icon.success {
    display: flex;
    img {
        width: 0.8rem;
        height: 0.8rem;
    }
}
.r-desc.success {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 0.48rem;
    line-height: 0.67rem;
    color: #00a2ff;
}

.guide-list:first-child {
    margin-top: 0;
}

@keyframes change {
    50% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(720deg);
    }
}
