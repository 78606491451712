.safety_module_broadcast {
  .switch_wrap {
    .customSwitch {
      --width: .95rem;
      --height: .57rem;
    }

    .adm-switch {
      .adm-switch-checkbox {
        border-radius: .55rem;
      }

      .adm-switch-spin-icon {
        width: .24rem;
        height: .24rem;
      }

      .adm-switch-checkbox, .adm-switch-checkbox:before {
        background: rgba(120, 120, 128, 0.16);
      }
    }

    .adm-switch-checked {
      .adm-switch-checkbox {
        border-radius: .55rem;
      }

      .adm-switch-checkbox, .adm-switch-checkbox:before {
        background: #2A69E2;
      }
    }

    .content_wrap {
      margin-bottom: .54rem;

      .text_wrap {
        font-weight: 600;
        font-size: .32rem;
        padding-top: .14rem;
        white-space: nowrap;
        color: #495160;
      }

      .text_wrap_detail {
        font-weight: 600;
        font-size: .26rem;
        padding-top: .14rem;
        white-space: nowrap;
        color: rgba(73, 81, 96, 0.6);
      }
    }
  }

  .left_dom_wrap {
    margin: .33rem 0 0 .3rem;
  }

  .right_dom_wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    img {
      width: 3rem;
      height: 3.06rem;
    }
  }

  .select_box {
    width: 100%;
    height: .8rem;
    line-height: .8rem;
    background: #E6E6E6;
    border-radius: .8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select_box_item {
    color: #495160;
    height: 100%;
    width: 1.68rem;
    font-weight: 500;
    font-size: .25rem;
    //text-align: center;

    display: flex;
    justify-content: center;

    .adm-spin-loading {
      margin: .05rem .1rem 0 0;
    }
  }

  .active {
    background: #FFFFFF;
    box-shadow: 0 .04rem .04rem rgba(71, 82, 101, 0.1);
    border-radius: .8rem;
  }
}

.black .safety_module_broadcast {
  .switch_wrap {
    .adm-switch {
      .adm-switch-checkbox, .adm-switch-checkbox:before {
        background: #2E3247;
      }

      .adm-switch-handle {
        background: #585D75;
      }

      .adm-switch-spin-icon {
        g {
          stroke: #ffffff;
        }
      }
    }

    .adm-switch-checked {
      .adm-switch-checkbox, .adm-switch-checkbox:before {
        background: #2A69E2;
      }

      .adm-switch-handle {
        background: #ffffff;
      }
    }

    .content_wrap {
      .text_wrap {
        color: #FFFFFF;
      }

      .text_wrap_detail {
        color: #FFFFFF;
      }
    }
  }


  .select_box {
    background: #2E3247;
  }

  .select_box_item {
    color: rgba(255, 255, 255, 0.7);;
  }

  .active {
    background: #585D75;
    box-shadow: 0 .04rem .04rem rgba(71, 82, 101, 0.1);
  }
}
