.p-info-perception {
    display: flex;
    gap: 0.7rem;
}

.p-info-perception-item {
    font-size: 0.28rem;
    font-weight: 500;
}

.p-info-perception-num {
    padding: 0 0.2rem;
    font-weight: 700;
    font-size: 0.4rem;
}
