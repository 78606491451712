.workflow-modal-container {
    width: 12rem;
    padding: 0.3rem 0.3rem 0.1rem;
    header {
        position: relative;
        width: 12rem;
        display: flex;
        align-items: center;
        font-size: 0.3rem;
        font-weight: bold;
        img {
            width: 0.33rem;
            height: 0.33rem;
            margin-right: 0.3rem;
        }
        label {
            position: absolute;
            top: 0;
            right: 1.6rem;
            font-size: 0.25rem;
            color: #8991a0;
            font-weight: normal;
        }
    }
    .workflow-info {
        padding: 0.2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .info-title {
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: 0.28rem;
            color: #495160;
            padding-bottom: 0.12rem;
        }
        .info-item {
            font-size: 0.24rem;
            color: #8991a0;
            padding-bottom: 0.16rem;
        }
        .col1 {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    .workflow-status-list {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        gap: 0.7rem;
    }
    .workflow-status-item {
        flex-shrink: 0;
        width: 4.88rem;
        background: #f0f3ff;
        border-radius: 10px;
        padding: 0.18rem 0.32rem;
        text-align: center;
        position: relative;
        .status-title {
            color: #323339;
            font-size: 0.28rem;
        }
        p {
            padding-top: 0.04rem;
            color: #8991a0;
            font-size: 0.24rem;
            min-height: 0.71rem;
            word-break: break-all;
            text-align: start;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .adm-tag {
            margin-top: 0.1rem;
            font-size: 0.22rem;
            padding: 0.1rem;
            display: inline-flex;
            align-items: center;
            img {
                width: 0.24rem;
                height: 0.24rem;
                margin-right: 0.07rem;
            }
        }
        &:not(:last-child)::after {
            content: '';
            position: absolute;
            width: 0.7rem;
            height: 0.13rem;
            background: url('~@/assets/workbench/workflow/arrow.png') no-repeat 0 0 / 100% 100%;
            right: -0.7rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .btn-box {
        width: 100%;
        padding-top: 0.28rem;
        .adm-button {
            font-size: 0.26rem;
        }
    }
    .rotate {
        animation: rotate 1s linear infinite;
    }
    @keyframes rotate {
        form {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .running-status-modal {
        padding: 0.3rem;
        .adm-modal-title {
            font-size: 0.3rem;
            color: red;
            padding-bottom: 0.1rem;
        }
        .adm-modal-close {
            font-size: 0.24rem;
        }
        .adm-modal-footer {
            display: none;
        }
        li {
            font-size: 0.24rem;
        }
    }
}

.workflow-dialog {
    .adm-dialog-content {
        font-size: 0.32rem;
        padding: 0.2rem 0.12rem 0.4rem;
    }
    .adm-dialog-footer .adm-dialog-action-row .adm-dialog-button {
        font-size: 0.3rem;
        padding: 0.3rem;
    }
}
