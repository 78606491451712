.card_wrap {
    width: 6.06rem;
    height: 3.06rem;
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 0.42rem;
    padding: 0.15rem;
    margin-bottom: 0.3rem;
}

.black .card_wrap {
    background: #383d54;
}
.is_mtour_todisabled {
    opacity: 0.4;
}
