.tipsItem {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 5.85rem;
    height: 6.12rem;
    background: rgba(54, 58, 86, 0.86);
    border-radius: 0.22rem;
    z-index: 99;
    text-align: center;
    img {
        margin: 0 auto;
        width: 5.11rem;
        height: 5.11rem;
    }
    p {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 0.45rem;
        line-height: 0.4rem;
        color: #fff;
    }
}
