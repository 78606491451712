.pnp-debug-wrap {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(1.35rem);
    background: #24283e;
    border-radius: 0.16rem;
    padding: 0.6rem 0 0 0;
}

.pnp-debug-title {
    display: flex;
    align-items: center;
    /* margin-bottom: 0.1rem; */
    position: absolute;
    top: 0;
    left: 0;
    .pnp-debug-close {
        width: 0.6rem;
        height: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: url(../../../../assets/parking/d_new/cancel_d.png) center
        //   center/0.3rem 0.3rem no-repeat;
        &-icon {
            width: 0.3rem;
            height: 0.3rem;
        }
    }
    .pnp-debug-name {
        font-size: 0.28rem;
        color: rgba(255, 255, 255, 0.4);
        white-space: nowrap;
    }
}

.pnp-debug-btn {
    width: 2.93rem;
    height: 0.76rem;
    line-height: 0.76rem;
    border-radius: 0.08rem;
    font-size: 0.26rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0.2rem 0.3rem 0.2rem;
    color: #ffffff;
}

.pnp-debug-btn.active {
    background: rgba(42, 105, 226, 0.1);
    color: #9abdff;
}
