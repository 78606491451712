.study-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgb(24, 28, 49);
    display: flex;
}

.study-status.sr {
    background: #fff;
}

.study-status.sr h3,
.study-status.sr label,
.study-status.sr p {
    color: #000;
}

.study-status.sr i {
    color: #2a69e2;
}
.study-status.sr .study-btn {
    color: #fff;
}

.study-status-left {
    padding-left: 2.66rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    h3 {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 0.62rem;
        line-height: 0.98rem;
        color: #fff;
        display: flex;
        align-items: center;
        .p-study-complete {
            width: 0.7rem;
            height: 0.7rem;
            margin-right: 0.2rem;
        }
    }
    label {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 0.48rem;
        line-height: 143%;
        color: rgba(255, 255, 255, 0.8);
        display: block;
        margin: 0.47rem 0 0.2rem 0;
    }

    p {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 0.48rem;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 0.2rem;
        i {
            color: #fff;
            font-style: normal;
            font-weight: 600;
            font-size: 0.5rem;
        }
    }
    .study-btn {
        width: 3.4rem;
        height: 1.22rem;
        line-height: 1.22rem;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 0.36rem;
        text-align: center;
        border-radius: 0.16rem;
        background: #2a69e2;
        color: #fff;
        position: absolute;
        top: 0.42rem;
        left: 2.22rem;
    }
    .close-btn {
        position: absolute;
        top: 0.42rem;
        left: 0.52rem;
        width: 1.32rem;
        height: 1.32rem;
        background: #24283e;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 0.366rem;
            height: 0.366rem;
        }
    }
    .d-cancel {
        width: 1.22rem;
        height: 1.22rem;
        margin-left: 0.42rem;
        position: absolute;
        background: #24283e;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.148rem;
        left: 0.2rem;
        top: 0.42rem;
        img {
            width: 0.6rem;
            height: 0.6rem;
        }
    }
}

.study-status-right {
    width: 9rem;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    img {
        width: 10.95rem;
        height: 6.54rem;
    }
}
