.p-info-speed {
    font-size: 0.4rem;
    font-weight: 700;
    color: #626873;
    .unit {
        margin-left: 0.2rem;
    }
    .p-info-speed-num {
        padding-left: 0.25rem;
    }
}

.p-info-speed.d {
    color: #fff;
    .p-info-speed-num {
        padding-left: 0;
    }
}
.p-info-speed.fast {
    color: red;
    animation: 1s blink infinite;
}
@keyframes blink {
    50% {
        color: transparent;
    }
}
