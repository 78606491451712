.car-info-tip-modal.adm-modal-body {
    padding: 0.2rem 0.3rem;
    .adm-modal-title {
        font-size: 0.3rem;
        margin-bottom: 0.2rem;
    }
    .adm-modal-close {
        font-size: 0.3rem;
    }
    .adm-modal-content {
        padding: 0 0.12rem;
        .adm-tag,
        .tip {
            font-size: 0.24rem;
        }
    }
    .adm-modal-footer {
        display: none;
    }
}
