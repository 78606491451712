.collision-avoid{
    position: absolute;
    left: 2.9rem;
    top: 1.4rem;
    z-index: 999;
}

.collision-avoid-car-wrap {
    display: flex;
    background: rgba(56, 61, 84, 1);
    border-radius: .12rem;
    width: 2.86rem;
    height: .81rem;
    align-items: center;
    border-radius: .12rem;
    img{
        width: .61rem;
        height: .61rem;
        margin-left: .1rem;
    }
    span{
        font-size: .3rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        flex: 1;
    }
}

.collision-avoid-vru-wrap {
    display: flex;
    background: rgba(56, 61, 84, 1);
    width: 2.29rem;
    height: .81rem;
    align-items: center;
    border-radius: .12rem;
    img{
        width: .61rem;
        height: .61rem;
        margin-left: .14rem;
    }
    span{
        font-size: .3rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        flex: 1;
    }
}