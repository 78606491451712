.odd_component_wrapper{
    .tipsItem {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 4.56rem;
        height: 4.79rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 .03rem .56rem rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(.8rem);
        border-radius: .3rem;
        z-index: 99;
        text-align: center;
        img {
            width: 3.6rem;
            height: 3.6rem;
        }
        p {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 400;
            font-size: .34rem;
            line-height: .31rem;
            color: #fff;
        }
    }
}