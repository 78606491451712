.reconnection_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: .1rem;
    font-weight: 500;
    font-size: .18rem;
  }
}
.reconnection_wrap.hide{
  visibility: hidden;
}
