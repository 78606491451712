.apa-show-area {
    position: absolute;
    height: 1.1rem;
    top: 0.42rem;
    left: 0.42rem;
    display: flex;
    align-items: center;
    gap: 0.34rem;
}

.p-status-item {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.3rem;
    border-radius: 0.16rem;
    border-top: none;
    height: 1.22rem;
}

.p-status-item.d {
    background: #24283e;
}

.p-status-item.sr {
    background: rgba(245, 249, 255, 0.8);
    box-shadow: 0px 0.178rem 0.203rem #cdd5e2;
    border-radius: 0.16rem;
}

.p-status-icon {
    // width: .75rem;
    height: 0.7rem;
    // border-radius: 50%;
    margin-right: 0.24rem;
}

.p-status-txt-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.08rem;
}

.p-status-txt {
    font-size: 0.34rem;
    font-weight: 500;
}

.p-status-sub-txt {
    color: #95989f;
    font-size: 0.24rem;
    margin-top: 0.1rem;
}

.p-status-txt.d {
    color: #fff;
}

.p-status-txt.sr {
    color: #535861;
}

.p-status-txt.sr.disabled {
    color: #fff;
}

.p-status-txt.sr.disabled {
    color: #606a7e;
}

.p-route-process-antd {
    padding-right: 0.15rem;
    .font-title {
        color: #2a69e2;
        font-size: 0.24rem;
    }
}

.p-route-process {
    width: 0.62rem;
    height: 0.62rem;
    line-height: 0.62rem;
    font-size: 0.15rem;
    font-family: 'Roboto';
    font-style: normal;
    text-align: center;
    margin-right: 0.18rem;
    font-weight: bold;

    position: relative;
}

.p-route-process > em {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 0.18rem;
}

.p-route-process.d > em {
    color: #fff;
}

.p-route-process.sr > em {
    color: rgba(42, 105, 227, 1);
}

.p-route-process > img {
    width: 100%;
}

.p-route-process-txt {
    font-size: 0.32rem;
    font-weight: 500;
}

.p-route-process.d .p-route-process-txt {
    color: #fff;
}

.p-route-process.sr .p-route-process-txt {
    color: #fff;
}
