.follow-distance {
    .follow-distance-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .distance-iconfont {
        font-size: 0.3rem;
        display: flex;
        justify-content: center;
        color: #fff;
        padding: 0.3rem;
    }
    .follow-distance-wrapper.false {
        .iconfont.distance-iconfont {
            color: rgba(255, 255, 255, 0.2);
        }
    }
    &-btn-add {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-btn-plus {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-content {
        text-align: center;
        color: #fff;
        font-weight: 300;
        font-size: 0.51rem;
        padding: 0.25rem 0;
    }
}
.follow-distance-c {
    .distance-iconfont,
    .follow-distance-content {
        color: #657086;
    }
}
.follow-distance-c.false {
    .follow-distance-btn-add {
        .iconfont.distance-iconfont {
            color: #cfd5dd !important;
        }
    }
    .follow-distance-btn-plus {
        .iconfont.distance-iconfont {
            color: #cfd5dd !important;
        }
    }
}
