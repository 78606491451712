.custom_toast_error .adm-toast-mask .adm-toast-main-text {
    background: #ff0000 !important;
}

.custom_toast_warn .adm-toast-mask .adm-toast-main-text {
    background: rgba(255, 116, 0, 0.85) !important;
}

.custom_toast_normal .adm-toast-mask .adm-toast-main-text {
    background: rgba(2, 104, 234, 0.85) !important;
}
.horizontal.adm-toast-mask .adm-toast-main {
}
.vertical.adm-toast-mask .adm-toast-main {
    left: 50% !important;
    top: 35% !important;
}
.left.horizontal.adm-toast-mask .adm-toast-main {
    left: 81% !important;
    top: 70% !important;
}
.adm-mask.adm-toast-mask {
    z-index: 998;
}
