.loading-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 990;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        display: block;
        width: 0.78rem;
        height: 0.78rem;
        position: absolute;
        top: 0.1rem;
        left: 0.1rem;
        top: 0.4rem;
        right: 0.4rem;
        background: url(../../../../assets/parking/close.png) 0 0 / cover no-repeat;
    }
    img {
        width: 1.34rem;
        height: 1.34rem;
    }
}
