.card_wrap {
  width: 6.06rem;
  height: 3.06rem;
  box-sizing: border-box;
  background: #F3F3F3;
  border-radius: .42rem;
  padding: .15rem;
  margin-bottom: .3rem;
}

.black .card_wrap {
  background: #383D54;
}
