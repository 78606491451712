.workbench-switch-card {
    width: 6.06rem;
    height: 6.42rem;
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 0.42rem;
    padding: 0.48rem 0.45rem;
    margin-bottom: 0.3rem;
    white-space: nowrap;
    color: #495160;
    &.disabled {
        opacity: 0.4;
    }
    .title {
        font-weight: 600;
        font-size: 0.32rem;
    }
    .sub-title {
        margin: 0.3rem 0 0.2rem;
        font-weight: 600;
        font-size: 0.28rem;
    }
    .tip {
        font-weight: 600;
        font-size: 0.26rem;
        padding-bottom: 0.14rem;
        white-space: normal;
        color: rgba(73, 81, 96, 0.6);
    }
}
.black .workbench-switch-card {
    background: #383d54;
    color: #ffffff;
    .tip {
        color: #fff;
    }
}
