.driving-assist-setting {
    width: 100%;
    height: 100%;
    padding: 0 1.31rem 0 1.33rem;
    .driving-assist-setting-item {
        padding-top: 0.9rem;
        .driving-assist-setting-item-title {
            font-weight: 500;
            font-size: 0.29rem;
            line-height: 0.29rem;
            color: #495160;
        }
        .driving-assist-setting-item-content {
            margin-top: 0.44rem;
        }
    }
}

.driving-assist-setting.black,
.Layout-box.black {
    .driving-assist-setting-item-title {
        color: #ffffff;
    }
    .switch-toast {
        background: #383d54;
        .switch-toast-text {
            color: #ffffff;
        }
    }
}
