.setting-modal-box .switch-toast {
    position: fixed;
    top: 0.4rem;
    left: 2.09rem;
    width: 18.8rem;
    height: 0.96rem;
    line-height: 0.96rem;
    background: #f3f3f3;
    border-radius: 0.19rem;
    .switch-toast-text {
        font-weight: 500;
        font-size: 0.32rem;
        text-align: center;
        color: #495160;
    }
}
