.lpnp-setting-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lpnp-setting-content {
    // width: 9.5rem;
    border-radius: 0.16rem;
    position: relative;
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.3rem;
    width: auto;
    flex-wrap: wrap;
    max-height: 9.3rem;
    flex-direction: row;
    writing-mode: vertical-lr;
}

.lpnp-setting-content.d {
    background: #24283e;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.8);
    padding: 0.5rem 1rem;
}

.lpnp-setting-content.sr {
    background: #f0f4fa;
    box-shadow: 0 0.21rem 24rem 0 #bdc5d4;
    border-radius: 0.16rem;
    padding: 0.5rem 1rem;
}

.lpnp-setting-close {
    position: absolute;
    top: 0rem;
    left: 0rem;
    padding: 0.32rem;

    > img {
        width: 0.4rem;
        height: 0.4rem;
    }
}

.lpnp-setting-item {
    display: flex;
    font-weight: 400;
    font-size: 0.3rem;
    letter-spacing: 0.04rem;
    display: flex;
    gap: 0.7rem;
    align-items: center;
    writing-mode: horizontal-tb;

    .customSwitch {
        --width: 0.88rem;
        --height: 0.5rem;
        --checked-color: #2a69e3;

        .adm-switch-checkbox {
            border-radius: 0.5rem;
        }

        .adm-switch-checkbox:before {
            background: #97a4be;
        }
    }
}

.lpnp-setting-item.d {
    color: #ffffff;
}

.lpnp-setting-item.sr {
    color: #696f7b;
}
