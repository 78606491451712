.p-learning-cancel {
    width: 1.22rem;
    height: 1.22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.16rem;
    border-top: none;
}

.p-learning-cancel.d {
    background: #24283e;
}
.p-learning-cancel.sr {
    background: #cbd6ee; //linear-gradient(180deg, rgba(255, 255, 255, 0) -18.18%, rgba(255, 255, 255, 0.3) 91.67%);
    // box-shadow: 0px .21rem .24rem #DDE5F1;
}

.p-cancel-icon {
    width: 0.66rem;
    height: 0.66rem;
}
