.hnp-Container {
    height: 100%;
    width: 100%;
}
.condition-icon {
    width: 0.58rem;
    height: 0.58rem;
    border-radius: 50%;
}
.hnp-Container-left-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0rem;
    left: 0.2rem;
    margin-top: 1.2rem;
    overflow: hidden;
    .refreshBtn {
        position: absolute;
        bottom: 4.5rem;
        width: 0.75rem;
        height: 0.75rem;
        background: #272b3e;
        border-radius: 0.16rem;
        line-height: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .fontStyle {
            font-size: 0.28rem;
            color: #fff;
        }
    }
    .debugmode {
        position: absolute;
        bottom: 1.2rem;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.1rem;
        text-align: center;
        color: #fff;
        background-color: rgba(255, 48, 30, 0.6);
        .modeTitle {
            font-size: 0.23rem;
            word-wrap: none;
        }
    }
}

// 横屏
.horizontal {
    .hnp-Container-left {
        width: 3.02rem;
        height: 1.12rem;
        // background: linear-gradient(90deg, #191C2F 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;
        /*width: 20%;
        height: 8.9rem;
        background: linear-gradient(90deg, #191C2F 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;*/
    }
    .hnp-Container-middle {
        width: 1.74rem;
        height: 0.6rem;
        position: absolute;
        left: 4.09rem;
        // right: 4.89rem;
        top: 0.24rem;
        // margin: 0 auto;
    }
    .hnp-container-driving-state {
        position: absolute;
        top: 6.5rem;
        left: 0rem;
    }
    .hnp-Container-bttom-left {
        width: 1.72rem;
        //height: 8.5rem;
        height: 9rem;
        // background: linear-gradient(90deg, #191C2F 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;
    }
    .positionState {
        position: absolute;
        //left: 0.5rem;
        //top: 9.67rem;
        left: 0.54rem;
        top: 9.2rem;
        height: 1.02rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* left: .5rem;
        top: 10.32rem;
        width: 3.04rem;
        height: .48rem;
        line-height: .48rem;*/
        font-family: 'PingFang SC';
        font-style: normal;
        font-size: 0.28rem;
        color: #fff;
        .positionNum {
            font-weight: bold;
            font-size: 0.4rem;
        }
        /*span {
            font-weight: bold;
            margin-left: .3rem;
        }*/
    }
    .tipsItem {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        /*width: 3.1rem;
        height: 2.54rem;
        background: rgba(240, 241, 243, 0.9);
        border-radius: .22rem;*/
        width: 4.56rem;
        height: 4.79rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(0.8rem);
        border-radius: 0.3rem;
        z-index: 99;
        text-align: center;
        img {
            /* margin: .42rem 0 .14rem;
            width: 1.71rem;
            height: 1.20rem;*/
            width: 3.6rem;
            height: 3.6rem;
        }
        p {
            font-family: PingFang SC;
            font-style: normal;
            /*font-weight: 500;
            font-size: .3rem;
            line-height: .4rem;
            color: #37383D;*/
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #fff;
        }
    }
    .tipsItemGif {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 6.53rem;
        height: 6.53rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(0.8rem);
        border-radius: 0.3rem;
        z-index: 99;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
            /* margin: .42rem 0 .14rem;
            width: 1.71rem;
            height: 1.20rem;*/
        }
        .steeringwheel {
            width: 3.04rem;
            height: 3.04rem;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.91rem;
        }
        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #ffffff;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.61rem;
        }
    }
    .refresh {
        position: absolute;
        /* left: .5rem;
        top: 1.3rem;*/
        //left: 0.5rem;
        //top: 8.43rem;
        left: 0.54rem;
        top: 8.2rem;
        width: 1.02rem;
        height: 1.02rem;
        background: #383d54;
        box-shadow: 0rem 0.04rem 0.1rem rgba(0, 0, 0, 0.1);
        line-height: 1.02rem;
        border-radius: 0.12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .fontStyle {
            font-size: 0.45rem;
            color: #b0bcf8;
        }
    }
    .ditance-controll {
        background: #383d54;
        box-shadow: 0rem 0.04rem 0.1rem rgba(0, 0, 0, 0.1);
        position: absolute;
        /* left: 0.5rem;
        top: 6.43rem;*/
        //left: .5rem;
        //top: 3.8rem;
        left: 0.54rem;
        top: 4.5rem;
        width: 1.02rem;
        border-radius: 0.12rem;
    }
}

// 竖屏
.vertical {
    .hnp-Container-left {
        width: 3.02rem;
        height: 1.12rem;
        background: linear-gradient(90deg, #191c2f 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;
        /*width: 20%;
        height: 8.9rem;
        background: linear-gradient(90deg, #191C2F 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;*/
    }
    .hnp-Container-middle {
        width: 1.74rem;
        height: 0.6rem;
        position: absolute;
        //left: 6rem;
        //top: 1.48rem;
        left: 5.11rem;
        top: 0.24rem;
    }
    .hnp-container-resume {
        position: absolute;
        top: 1.4rem;
        left: 0.54rem;
    }
    .hnp-Container-bttom-left {
        width: 1.72rem;
        //height: 8.2rem;
        height: 8.1rem;
        background: linear-gradient(90deg, #191c2f 0%, rgba(25, 28, 47, 0.76) 60.72%, rgba(25, 28, 47, 0) 93.79%);
        margin-top: 1.2rem;
    }
    .positionState {
        position: absolute;
        /* left: .3rem;
        top: 10.0rem;
        width: 3.04rem;
        height: .48rem;
        line-height: .48rem;*/
        /*left: 1.52rem;
        top: 1.28rem;*/
        left: 0.54rem;
        //top: 8.28rem;
        top: 8.3rem;
        height: 1.02rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-size: 0.28rem;
        color: #fff;
        .positionNum {
            font-size: 0.4rem;
            font-weight: bold;
        }
        /*span {
            margin-left: .3rem;
            font-weight: bold;
        }*/
    }
    .tipsItem {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        /*width: 3.1rem;
        height: 2.54rem;
        background: rgba(240, 241, 243, 0.9);
        border-radius: .22rem;*/
        width: 4.56rem;
        height: 4.79rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(0.8rem);
        border-radius: 0.3rem;
        z-index: 99;
        text-align: center;
        img {
            width: 3.6rem;
            height: 3.6rem;
            /*margin: .42rem 0 .14rem;
            width: 1.71rem;
            height: 1.20rem;*/
        }
        p {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #ffffff;
        }
    }
    .tipsItemGif {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 6.53rem;
        height: 6.53rem;
        background: rgba(113, 133, 170, 0.22);
        box-shadow: 0 0.03rem 0.56rem rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(0.8rem);
        border-radius: 0.3rem;
        z-index: 99;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
            /* margin: .42rem 0 .14rem;
             width: 1.71rem;
             height: 1.20rem;*/
        }
        .steeringwheel {
            width: 3.04rem;
            height: 3.04rem;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.91rem;
        }
        p {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 0.31rem;
            color: #ffffff;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 0.61rem;
        }
    }
    .refresh {
        position: absolute;
        left: 0.54rem;
        //top: 6.78rem;
        top: 7.3rem;
        width: 1.02rem;
        height: 1.02rem;
        background: #383d54;
        box-shadow: 0rem 0.04rem 0.1rem rgba(0, 0, 0, 0.1);
        line-height: 0.7rem;
        border-radius: 0.12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .fontStyle {
            font-size: 0.5rem;
            color: #b0bcf8;
        }
    }
    .ditance-controll {
        background: #383d54;
        box-shadow: 0rem 0.04rem 0.1rem rgba(0, 0, 0, 0.1);
        position: absolute;
        width: 1.02rem;
        left: 0.54rem;
        //top: 3.1rem;
        top: 3.7rem;
        border-radius: 0.12rem;
    }
}
.hnp-Container {
    .warningBackground {
        width: 100%;
        height: 4rem;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(42, 105, 227, 0.8) 0%, rgba(42, 105, 227, 0) 93.75%) no-repeat;
        background-size: 100% 4rem;
        z-index: 999;
        -webkit-animation: twinkling 0.5s infinite ease-in-out;
    }
    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}
@-webkit-keyframes twinkling {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes twinkling {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
