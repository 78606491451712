.work-bench {
    position: fixed;

    z-index: 9999;
    background: #f5f5f5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.4rem 0.48rem;
}
.work-bench-base1 {
    padding: 0 0.06rem;
}

.work-bench-title {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;

    .work-bench-close {
        display: inline-block;
        padding: 0.08rem 0;
        margin-right: 0.2rem;

        img {
            width: 0.3rem;
            height: 0.3rem;
        }
    }

    h3 {
        font-size: 0.34rem;
        font-weight: 500;
        color: rgba(73, 81, 96, 1);
    }
}

.work-bench-content {
    display: grid;
    grid-template-columns: 5.05rem 7.57rem 7.57rem;
    gap: 0.22rem;
    height: 90vh;

    .left {
        display: flex;
        flex-direction: column;
    }

    .right {
        background: blue;
    }

    .work-bench-base {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-radius: 0.16rem;
        background: rgba(255, 255, 255, 1);
        padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        margin-bottom: 0.22rem;
        position: relative;

        .between {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h4 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            font-size: 0.28rem;
        }

        &-content {
            display: flex;
            flex-direction: column;
            height: 100%;

            &-item {
                flex: 1;
                margin-top: 0.22rem;
                font-size: 0.28rem;

                h5 {
                    font-weight: normal;
                    color: rgba(73, 81, 96, 1);
                }

                p {
                    color: rgba(137, 145, 160, 1);
                    margin-top: 0.22rem;
                }
            }
        }

        &-content-disk {
            height: 100%;
            font-size: 0.28rem;
            color: rgba(73, 81, 96, 1);
            &-item {
                margin-top: 0.22rem;
                display: flex;
                justify-content: space-between;

                span:nth-child(2) {
                    color: rgba(137, 145, 160, 1);
                }
            }
        }

        &-content-distance {
            height: 100%;
            font-size: 0.28rem;
            color: rgba(73, 81, 96, 1);

            &-item {
                margin-top: 0.22rem;
                display: flex;
                justify-content: space-between;
                margin: 0.3rem 0;
                span:nth-child(2) {
                    color: rgba(137, 145, 160, 1);
                }
            }
        }

        &-dick-info {
            display: flex;
            justify-content: center;

            &-left {
                color: #2a69e2;
            }

            &-middle {
                color: #8991a0;
                padding: 0 0.05rem;
            }

            &-right {
                color: #495160;
            }
        }

        &-upload-content {
            margin-top: 0.24rem;
            height: 82vh;
            overflow: hidden;

            .adm-empty-description {
                font-size: 0.24rem;
            }

            .tip {
                padding-top: 2.5rem;
            }
        }

        &-lists {
            &-item {
                padding: 0.31rem 0.36rem;
                background: #f8f9fb;
                position: relative;
                margin-bottom: 0.22rem;
            }

            &-status {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                gap: 0.1rem;
                img {
                    width: 0.64rem;
                    height: 0.42rem;
                }
            }

            &-title {
                display: flex;
                align-items: center;
                color: #8991a0;
                font-size: 0.24rem;

                .time {
                    margin-right: 0.2rem;
                }
                &.link .time {
                    text-decoration: underline;
                }
            }

            &-content {
                margin-top: 0.35rem;
                display: flex;
                justify-content: space-between;
                position: relative;

                .work-bench-img {
                    width: 40%;
                    height: 1.72rem;
                    margin-right: 4%;
                }

                .work-bench-audio {
                    // width: 60%;
                    height: 1.72rem;
                }

                .work-bench-base-lists-effect {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &-loading {
                        position: relative;
                        width: 0.4rem;
                        height: 0.4rem;
                        border: 0.04rem solid #1cadff;
                        border-top-color: rgba(0, 0, 0, 0.2);
                        border-right-color: rgba(0, 0, 0, 0.2);
                        border-bottom-color: rgba(0, 0, 0, 0.2);
                        border-radius: 100%;
                        animation: upload_loading infinite 0.75s linear;
                    }
                }
                .right-content-img {
                    position: relative;
                }

                .work-bench-base-lists-effect::before {
                    display: table;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: inherit;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    box-shadow: inset 0 0 0 1rem rgb(255 255 255 / 49%);
                    backdrop-filter: blur(0.1rem);
                    opacity: 0.5;
                }

                .work-bench-audio-icon {
                    width: 0.45rem;
                    height: 0.45rem;
                    position: absolute;
                    left: 0.27rem;
                    bottom: 0.2rem;
                    z-index: 10;

                    > img {
                        width: 100%;
                    }
                }
            }

            .list-no-more {
                font-size: 0.3rem;
                padding: 0.2rem 0px;
                text-align: center;
                color: #8991a0;
            }
        }
    }
    .hide {
        display: none;
    }
}

@keyframes upload_loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

#container {
    width: 2.08rem;
    height: 2.08rem;
    margin: 0 auto;
}

.header-content {
    // padding: .10rem;
    position: relative;
    .icon-content {
        position: absolute;
        font-size: 0.35rem;
        top: -0.05rem;
        right: -0.05rem;
    }
    .icon-refresh {
        position: absolute;
        font-size: 0.35rem;
        top: -0.05rem;
        right: 0.5rem;
    }
    .loading {
        animation: search_loading infinite 1s linear;
    }
    .tooltip {
        position: absolute;
        // position:relative;
        right: 0;
        top: 0.4rem;
        display: none;
    }
    .tooltip.show {
        padding: 0rem;
        display: block;
        box-shadow: 0 0 30px 0 rgb(51 51 51 / 20%);
    }
    p {
        background-color: #fff;
        border: solid 0.01rem #f6f6f6;
        border-radius: 0.05rem;
        width: 100%;
        font-size: 0.22rem;
        line-height: 0.28rem;
        padding: 0.12rem;
        padding-right: 0.05rem;
    }

    .tooltip.top > .arrow {
        top: -0.2rem;
        right: 0.08rem;
        border-bottom-color: #f6f6f6;
        border-width: 0.1rem;
    }

    .tooltip.top > .arrow:after {
        content: ' ';
        top: -0.09rem;
        left: -0.1rem;
        border-bottom-color: #fff;
        border-width: 0.1rem;
    }

    .arrow,
    .arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
}

@keyframes search_loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-360deg);
    }
}
