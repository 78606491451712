.modalMask{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(34, 46, 69, 0.4);
  z-index:1002;
}
.modalMask.naviSet{
  .maskContent{
    margin-top: .24rem;
  }
}
.modalBox{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: keep-all;
}
.modalCom{
  width: auto;
  // width: 80%;
  padding: 0.34rem 0.47rem .6rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 .04rem .3rem rgba(0, 0, 0, 0.05);
  border-radius: .32rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
  .close{
   /* margin-left: -2.2rem;
    margin-top: -0.2rem;*/
    font-size: .23rem;
    width: .2rem;
    height: .2rem;
    color: #3D3D3D;
    margin-left: -2%;
  }
  .maskContent{
    // padding-top: .51rem;
  }
  .name{
    font-weight: 500;
    font-size: .26rem;
    color: rgba(0, 0, 0, 0.7);
    padding-bottom: .1rem;
  }
  .title{
    padding-bottom: .1rem;
  }
  .desc{
    font-size: .18rem;
    font-weight: 400;
    color: #797A7D;
  }
  .select-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E6E6E6;
    // width: 100%;
    // border: .02rem solid #D7D9DE;
    border-radius: .13rem;
    &-item{
      text-align: center;
      flex: 1;
      line-height: .25rem;
      padding: .31rem .55rem;
      font-size: .28rem;
      color: #808080;
      font-weight: 400;
    }
    &-item.active{
      background: #F9FAFD;
      box-shadow: 0 .04rem .04rem rgba(71, 82, 101, 0.1);
      border-radius: .13rem;
      color: rgba(0, 0, 0, 0.7);
    }
  }
  .select-box.disabled{
    .select-box-item{
      color: #DBDDE2;
    }
  }
  .select-group{
    margin-bottom: .1rem;
    &-item{
      margin-bottom: .27rem;
    }
  }
  .version{
    // padding-top: 1.54rem;
    padding-top: .85rem;
    color: #A7A8AB;
    &-name{
      font-size: .23rem;
      font-weight: 500;
      line-height: .25rem;
      padding-bottom: .17rem;
    }
    &-info{
      font-size: .21rem;
      font-weight: 400;
      color: #BABCBF;
      line-height: .3rem;
      &-name{
        margin-right: .2rem;
      }

    }
  }
  .switch{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .customSwitch{
      --width: .88rem;
      --height:.5rem;
      .adm-switch-checkbox{
        border-radius: .5rem;
      }
    }
    .TLANname{
      font-weight: 500;
      font-size: .28rem;
      color: #909090;
      opacity: 0.7;
      padding-left: .35rem;
    }
  }

}

.vertical {
  .modalCom{
    width: 95%;
    height: 95%;
    .maskContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .select-group-item {
      margin-right: 2rem;
    }
    .version {
      padding-top: 0.25rem;
    }
    .select-box{
      max-width: 7rem;
    }

  }

}

.sideBar.black, .Layout-box.black{
  .modalMask{
    background: rgba(9, 11, 28, 0.6);
  }
  .modalCom{
    background: #383D54;
    box-shadow: 0 .04rem .44rem rgba(0, 0, 0, 0.1);
    .close,.name,.desc,.version-info{
      color: #B0BCF8;
    }
    .select-box{
      background: #2E3247;
    }
    .select-box-item{
      color:#A7B0D8
    }
    .select-box-item.active{
      background: #6E77A8;
      box-shadow: 0 .04rem .04rem rgba(71, 82, 101, 0.1);
      color: #EAEDFF;
    }
    .select-box.disabled{
      background: #34394F;
      .select-box-item{
        color: #606684;
      }
      .select-box-item.active{
        background: #687096;
        color:#7F8398;
      }
    }
  }

}