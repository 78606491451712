
.slider-content{
    margin-top:.15rem;
   padding-right: .35rem;
   .adm-slider-track-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: .2rem;
        padding: 0.08rem 0;
    }
    .adm-slider-thumb-container{
        height: .32rem;
    }
    .adm-slider-tick {
        width: .1rem;
        height: .1rem;
        background-color: #e5e5e5;
    }
    .adm-slider-tick-active {
        background-color: #1677ff;
    }  
    .adm-slider-mark{
        font-size: .18rem;
        margin-top: .12rem;   
    }
    .adm-slider-thumb{
        width: 0.28rem;
        height: 0.28rem;
        margin: 0;
        padding: 0;
    }
    .adm-slider-track{
        background-color: #E6E6E6;
    }
    .adm-slider-thumb-icon{
        width: .12rem;
        height: .12rem;
        margin: .08rem;
    }
   
    .text_wrap{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: .25rem;
        line-height: .25rem;
        color: #495160;
        margin-bottom: .1rem;
    }
    .slider-black{
        color: #fff;
        .adm-slider-mark-text{
            color: #fff;
        }
    }
    .slider-white{
        color: #333;
        .adm-slider-mark-text{
            color: #333;
        }
    }
}


.card_wrap {
    width: 6.06rem;
    height: 3.06rem;
    box-sizing: border-box;
    background: #F3F3F3;
    border-radius: .42rem;
    padding: .15rem;
    margin-bottom: .3rem;
    .content_wrap .text-header{
        font-size: 0.32rem;
        font-weight: 600;
        // padding-top: 0.14rem;
        white-space: nowrap;
        color: #495160;
    }
   
  
  .black .card_wrap {
    background: #383D54;
  }
}
  