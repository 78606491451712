.lpnp-dashboard {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.lpnp-dashboard-mask {
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.lpnp-dashboard-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #292b3e;
    border-radius: 0.16rem;
    z-index: 20;
}

.lpnp-dashboard-close {
    padding: 0.32rem;
    position: absolute;
    left: 0;
    top: 0;
    > img {
        width: 0.23rem;
        height: 0.23rem;
    }
}

.lpnp-dashboard-mviz {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 20;
    background: #24283e;
    border-radius: 0.16rem;
    > iframe {
        width: 100%;
        height: 100%;
    }
}
