.nrp-container {
    .icon-nrp {
        position: absolute;
        right: 2.73rem;
        top: 2rem;
        width: 0.8rem;
        height: 0.8rem;
        &.sr {
            right: auto;
            left: 0.42rem;
            top: 3.84rem;
        }
    }
}
