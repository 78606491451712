.study-guide {
    background: linear-gradient(180deg, #dbe6fc 0%, #eaeff6 25.09%);
    width: 100%;
    height: 100%;
    padding-top: 0.42rem;
    &.d {
        background: linear-gradient(180deg, #181c31 0%, #181c31 25.09%);
        .opration-top .header-title {
            color: #fff;
        }
        .guide-list {
            background: #383d54;
            color: #ffffff;
            opacity: 1;
            .r-desc,
            .r-desc.success {
                color: #fff;
            }
            &.true {
                background: #00a2ff;

                .l-icon.true::before {
                    background: conic-gradient(transparent, transparent, #fff, #fff);
                }
            }
        }
        .guide-close-content {
            background: #24283e;
        }
    }

    .opration-top {
        display: flex;
        align-items: center;
        position: relative;
        .header-title {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 0.34rem;
            line-height: 157.5%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            color: #535861;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .guide-close-content {
        width: 1.22rem;
        height: 1.22rem;
        margin-left: 0.42rem;
        // position: relative;
        background: #cbd6ee;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.148rem;
        .guide-close-icon {
            width: 0.366rem;
            height: 0.366rem;
        }
    }
    .guide-content {
        width: 100%;
        display: inline-flex;
        padding-top: 1.8rem;
        .guide-left {
            width: 38%;
            overflow: hidden;
            // padding-right: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 6rem;
                height: 5.681rem;
            }
        }
        .guide-right {
            max-width: 60%;
            padding-right: 0.7rem;
        }
    }

    .guide-operate-btn {
        display: flex;
        align-items: center;
        border-radius: 0.16rem;
        border: none;
        box-sizing: border-box;
        border-radius: 0.16rem;
        height: 1.22rem;
        padding: 0 0.3rem;
        position: relative;
        outline: none;
        background: #2a69e3;
        margin-left: 0.55rem;
    }

    .guide-operate-btn.d::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: #2a69e3;
        border: none;
        border-color: #2a69e3;
        border-radius: inherit;
        transform: translate(-50%, -50%);
        opacity: 0;
        content: '';
    }

    // .guide-operate-btn:active::before {
    //     opacity: 0.6;
    // }
    .guide-operate-btn.active:active {
        box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
        // border-radius: 50%;
    }

    .guide-operate-btn > img {
        // width: 0.63rem;
        height: 0.63rem;
        margin-right: 0.21rem;
    }

    .guide-operate-btn > span {
        font-size: 0.34rem;
        font-weight: 500;
    }

    .guide-operate-btn > span {
        color: #fff;
    }
    .guide-operate-btn.disabled {
        opacity: 0.4;
    }
}
