.iconGif{
  position: absolute;
  top: 1.75rem;
  right: 2.8rem;
  width: .73rem;
  height: .74rem;
}
/*
.tipsItemGifBSD{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 5.06rem;
  height: 5.29rem;
  background: rgba(113, 133, 170, 0.22);
  box-shadow: 0 .03rem .56rem rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(.8rem);
  border-radius: .29rem;
  z-index: 99;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  img {
     width: 5.06rem;
     height: 3.92rem;
  }
  p {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: .38rem;
    line-height: .31rem;
    color: #FFFFFF;
    margin: auto;
  }
}*/
