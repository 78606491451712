.operation-content {
    position: fixed;
    left: 50%;
    top: 2.8rem;
    transform: translate(-50%, 0);
    border-radius: 0.16rem;
    height: 1rem;

    .apa-operate-btn {
        display: flex;
        align-items: center;
        border-radius: 0.16rem;
        border: none;
        box-sizing: border-box;
        border-radius: 0.16rem;
        height: 1rem;
        padding: 0 0.3rem;
        position: relative;
        outline: none;
        background: #2a69e3;
    }
    .apa-operate-btn > span {
        font-size: 0.34rem;
        font-weight: 500;
    }

    .apa-operate-btn > span {
        color: #fff;
    }
}
.operation-content:active {
    box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
    // border-radius: 50%;
}
