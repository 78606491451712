@img_src: '../../../assets/resume';
.imgState {
    width: 0.8rem;
    height: 0.8rem;
}
.running_mode_active {
    animation: active-show 1s ease-out;
}
.running_mode_standby {
    animation: standby-show 500ms ease;
}
.running_mode_quit {
    animation: quit-show 500ms ease;
}
.background_common {
    background-repeat: no-repeat;
    background-size: 100%;
}
.CP_STANDBY {
    background-image: url('@{img_src}/cp_standby.png');
    .background_common();
}
.HNP_STANDBY {
    background-image: url('@{img_src}/np_standby.png');
    .background_common();
}
.UNP_STANDBY {
    background-image: url('@{img_src}/np_standby.png');
    .background_common();
}

.HNP_PLUS_STANDBY {
    background-image: url('@{img_src}/np_plus_standby.png');
    .background_common();
}
.CP_ACTIVE {
    background-image: url('@{img_src}/cp_active.png');
    .background_common();
}
.CP_UPGRADE {
    background-image: url('@{img_src}/cp_upgrade.png');
    .background_common();
}
.HNP_ACTIVE {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}
.UNP_ACTIVE {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}

.HNP_CP_ACTIVE {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}
.UNP_CP_ACTIVE {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}
.HNP_CP_NO_STANDBY {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}
.UNP_CP_NO_STANDBY {
    background-image: url('@{img_src}/np_active.png');
    .background_common();
}
.HNP_UPGRADE {
    background-image: url('@{img_src}/np_upgrade.png');
    .background_common();
}
.HNP_PLUS_ACTIVE {
    background-image: url('@{img_src}/np_plus_active.png');
    .background_common();
}

@keyframes active-show {
    0% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.3);
    }
    70% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes standby-show {
    from,
    20% {
        opacity: 0;
    }
    30%,
    to {
        opacity: 1;
    }
}

@keyframes quit-show {
    from,
    50%,
    to {
        opacity: 1;
    }

    25%,
    75% {
        opacity: 0;
    }
}
