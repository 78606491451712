.apa-p-operate-btn-group {
    backdrop-filter: blur(0.04rem);
    display: flex;
    align-items: center;
    gap: 0.36rem;
    position: fixed;
    top: 0.42rem;
    left: 0.42rem;
    height: 1.22rem;
}
