.horizontal {
    .infor_tips {
    }
}
.vertical {
    .infor_tips {
        top: 5.5rem;
    }
}
.infor_tips{
    // position: absolute;
    padding: .1rem;
    // right: .24rem;
    // max-width: 2.5rem;
    width: 1.7rem;
    background-color: rgba(221,221,221,.4);
    border-radius: .07rem;
    p{
        color: #000;
        font-size: .18rem;
        height: .28rem;
        line-height: .28rem;
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
    }
    &_title{
        font-size: .2rem;
        font-weight: 500;
    }
}

.d .infor_tips{
    background-color: #24283E;
    p {
        color: #fff;
        white-space: normal;
        word-break: break-all;
        // display: inline-block;
        height: auto;
        overflow: visible;
    }
}
.black{
    .infor_tips{
        background-color: rgba(56, 61, 84, 0.6);
        p{
          color: #fff;
        }
    }
}
