.p-back-to-driving {
    width: 1.22rem;
    height: 1.22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.16rem;
    border-top: none;
    .icon-back {
        display: block;
        width: 0.22rem;
        height: 0.22rem;
        border: 0.06rem solid #626873;
        border-top: 0;
        border-right: 0;
        transform: rotate(45deg);
    }
}
.p-back-to-driving.d {
    background: #24283e;
}
.p-back-to-driving.sr {
    background: #cbd6ee;
}
.p-back-icon {
    width: 0.34rem;
    height: 0.34rem;
}
