.horizontal {
    .event_info {
        // bottom: 2.84rem;
        margin-bottom: .24rem;
    }
}
.vertical {
    .event_info {
        // top: 5.5rem;
    }
}
.event_info{
    // position: absolute;
    padding: .1rem;
    // right: .24rem;
    // max-width: 2.5rem;
    width: 1.7rem;
    background-color: rgba(221,221,221,.4);
    border-radius: .07rem;
    p{
        color: #000;
        font-size: .18rem;
        line-height: .28rem;
       /* white-space: nowrap;
        text-overflow:ellipsis;
        overflow:hidden;*/
    }
    .close{
        font-size: .18rem;
        text-align: right;
        padding: .1rem .1rem;
    }
    &_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_title{
        font-size: .24rem;
        font-weight: 500;
    }
    .item_wrap {
        font-Size: .22rem;
        margin-bottom: .22rem;
        word-wrap: break-word;
        word-break: normal;
    }
}
.black{
    .event_info{
        background-color: rgba(56, 61, 84, 0.6);
        p{
          color: #fff;
        }
        .close{
            color: #FFF;
        }
    }
}
