.etsa_wrap {
  width: .8rem;

  img {
    width: 100%;
    height: .8rem;
    margin-bottom: .2rem;
  }

  .end_speed_overtaking_wrap {
    width: 100%;
    position: relative;
    .speed_content {
      height: .8rem;
      width: .8rem;
      position: absolute;
      display:flex;
      justify-content:center;
      align-items:center;
      font-size: .32rem;
      font-weight: 700;
      color: #B7B7B7;
    }

    img {
      width: 100%;
      height: .8rem;
      margin-bottom: .2rem;
    }
  }
}
