.task-list-container {
    padding: 0.28rem;
    border-radius: 0.16rem;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .title {
        font-size: 0.28rem;
        font-weight: bold;
        margin-bottom: 0.24rem;
    }
    .tip {
        padding-top: 2.5rem;
        .adm-empty-description {
            font-size: 0.24rem;
        }
    }
    .task-list {
        flex: 1;
        overflow-y: scroll;
        .adm-list-item-content-arrow {
            font-size: 0.32rem;
            color: rgba(137, 145, 160, 1);
        }
        .task-info-item {
            font-size: 0.28rem;
            color: #495160;
            word-break: break-all;
            &:not(:first-child) {
                padding-top: 0.34rem;
            }
        }
        .btn-entry {
            font-size: 0.26rem;
            width: 100%;
            padding: 0.2rem 0;
        }
    }
    .task-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .task-desc {
            .task-name {
                display: flex;
                align-items: center;
            }
            .adm-tag {
                font-size: 0.22rem;
                padding: 0.1rem;
            }
            span:not(.adm-tag) {
                font-weight: 500;
                font-size: 0.3rem;
                color: #495160;
                padding-left: 0.14rem;
            }
            .task-time {
                font-size: 0.28rem;
                color: #8991a0;
                padding-top: 0.1rem;
            }
        }
        .deploy-status {
            font-size: 0.24rem;
            color: #8991a0;
        }
    }
}
