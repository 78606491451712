.custom_ldw_card_wrap {
  width: 6.06rem;
  height: 6.4rem;
  box-sizing: border-box;
  background: #F3F3F3;
  border-radius: .42rem;
  padding: .36rem .45rem;
  margin-bottom: .3rem;

  .title_wrap {
    font-size: .32rem;
    font-weight: 600;
  }

  .sensitivity_wrap,.ldw_warning_wrap {
    width: 100%;
    margin: .5rem 0 .5rem 0;

    .subtitle_wrap {
      font-weight: 500;
      font-size: .25rem;
      color: #495160;
      margin-bottom: .24rem;
    }
  }

  /*.switch_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .35rem;

    .text_wrap {
      display: inline-block;
      font-weight: 500;
      font-size: .25rem;
      margin-left: .2rem;
    }
  }*/
}

.black .custom_ldw_card_wrap {
  background: #383D54;

  .title_wrap {
    color: #FFFFFF;
  }

  .sensitivity_wrap, .ldw_warning_wrap {
    .subtitle_wrap {
      color: #FFFFFF;
    }
  }

  /*.switch_wrap {
    .text_wrap {
      color: #FFFFFF;
    }
  }*/
}


.custom_ldw_not_active {
  opacity: 0.4;
}

