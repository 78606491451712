.p-info-geer ul {
    display: flex;
    font-weight: 800;
    font-size: 0.28rem;
    gap: 0.35rem;
    align-items: center;
}

.p-info-geer ul li.active {
    color: #2a69e3;
    font-size: 0.48rem;
}
