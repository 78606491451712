.other_settings_wrap {
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 6.06rem 6.06rem;
    justify-content: space-between;
    .workbench-card {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .workbench-switch-card {
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-start: 2;
        grid-column-end: 3;
    }
}
