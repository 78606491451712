.takeOverBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  /*width: 3.1rem;
  height: 2.54rem;
  background: rgba(240, 241, 243, 0.9);
  border-radius: .22rem;*/
  width: 3.9rem;
  height: 3.9rem;
  background: #383D54;
  box-shadow: 0 .02rem .3rem rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(.22rem);
  border-radius: .16rem;
  z-index: 99;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    /* margin: .42rem 0 .14rem;
     width: 1.71rem;
     height: 1.20rem;*/
    width: 2.77rem;
    height: 1.93rem;
  }
  p {
    font-family: PingFang SC;
    font-style: normal;
    /*font-weight: 500;
    font-size: .3rem;
    line-height: .4rem;
    color: #37383D;*/
    font-weight: 500;
    font-size: .32rem;
    line-height: .23rem;
    margin-top: .2rem;
    color: #D1DEFD;
  }
}