.change-btn-all-status {
    .p-operate-btn {
        display: flex;
        align-items: center;
        border-radius: 0.16rem;
        border: none;
        box-sizing: border-box;
        border-radius: 0.16rem;
        height: 1.22rem;
        padding: 0 0.3rem;
        position: relative;
        outline: none;
    }
    .p-status-item.d {
        background: #0068eb;
    }
    .p-status-item.d:active {
        box-shadow: 0 0 0.1rem 0.1rem hsla(#1cadff, 30%, 41%, 0.9);
    }
    .p-status-sub-txt {
        &.d {
            color: #fff;
        }
        &.sr {
            color: #535861;
        }
    }
    .p-operate-btn.d::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: #2a69e3;
        border: none;
        border-color: #2a69e3;
        border-radius: inherit;
        transform: translate(-50%, -50%);
        opacity: 0;
        content: '';
    }
    .p-operate-btn.sr.disabled {
        opacity: 0.4;
    }
    .p-operate-btn.d.highlight {
        background: #2a69e3;
    }

    .p-operate-btn.sr {
        background-color: #2a69e3;
        color: #fff;
    }

    .p-operate-btn > img {
        height: 0.7rem;
        border-radius: 50%;
        margin-right: 0.24rem;
    }

    .p-operate-btn > span {
        font-size: 0.34rem;
        font-weight: 500;
    }

    .p-operate-btn.d > span {
        color: #fff;
    }

    .p-operate-btn.sr > span {
        color: #fff;
    }

    .p-operate-btn.disabled {
        opacity: 0.4;
    }
}
