.second_nrp_wrap {
    position: absolute;
    top: 11.55rem;
    z-index: 999;
    left: 0.23rem;

    .button_style {
        height: 0.85rem;
        font-size: 0.28rem;
        padding: 0 0.3rem;
        --border-radius: 8px;
        --background-color: #0068eb;
    }
}
