.record-wrapper {
    position: fixed;
    right: 0.24rem;
    bottom: 0.24rem;
    width: 0.84rem;
    height: 0.84rem;
    border-radius: 0.16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background: #ffffff;
    &.parking {
        right: 0.2rem;
        bottom: 0.2rem;
        &.sr {
            background: rgba(245, 249, 255, 0.8);
            box-shadow: 0 0.21rem 0.24rem 0 #dde5f1;
        }
        &.d {
            background: #24283e;
        }
    }
    &.dark {
        background: #383d54;
    }
}

.record-icon {
    width: 0.62rem;
    height: 0.62rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.recording {
    width: 0.62rem;
    height: 0.62rem;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}
.recording:active {
    box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
    border-radius: 50%;
}
