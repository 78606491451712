.rcta_icon_wrap {
    z-index: 10;

    .img_wrap {
        width: 0.48rem;
    }
}

.rctb_icon_wrap {
    z-index: 10;

    .img_wrap {
        width: 0.48rem;
    }
}
