.slider {
    width: 1.35rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0.43rem 0;
    //padding: .2rem 0 .53rem 0;
    &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1000;
    }
    &-item:not(:last-child) {
        margin-bottom: 0.45rem;
    }
    .iconfont-slider {
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        color: #fff;
    }
    .hands-off {
        margin-top: 0.1rem;
    }
    &-item.active {
        span {
            background: #0068eb;
            border-radius: 0.16rem;
        }
    }
    &-item.line.active {
        span {
            background: none;
            color: #0068eb;
        }
    }
    &-top {
        color: #ffffff;
        text-align: center;
        .position-num {
            font-weight: 300;
            font-size: 0.51rem;
        }
        .position-title {
            font-weight: 500;
            font-size: 0.18rem;
        }
    }
    &-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1000;

        ul {
            padding: 0.2rem 0.15rem;
        }

        &-content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            // width: auto;
            // margin: 0 auto;
            color: #9d9d9d;
        }
    }
}
.slider-c {
    .iconfont-slider,
    .slider-top {
        color: #657086;
    }
    .slider-item.active {
        span {
            color: #ffffff;
        }
    }
    .slider-item.line.active {
        span {
            background: none;
            color: #0068eb;
        }
    }
}
