.p-info-distance {
    font-weight: 500;
    font-size: 0.28rem;
}

.p-info-distance-num {
    font-weight: 700;
    font-size: 0.4rem;
    padding: 0 0.25rem;
}

.p-info-distance-m {
    font-weight: 700;
}
