.setting-mask-rads {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0.2rem;

    .setting-content {
        position: fixed;
        left: 0.15rem;
        min-height: 1rem;
        z-index: 997;
        background: #24283e;
        border-radius: 0.16rem;
        padding: 0.12rem;
        letter-spacing: 0.04rem;
        .back-btn {
            width: auto;
            padding: 0.25rem 0.3rem 0.25rem 0.3rem;
            display: block;
            font-size: 0.3rem;
            border-radius: 0.08rem;
            color: #9abdff;
            margin: 0 auto;
            white-space: nowrap;
            background: rgba(42, 105, 226, 0.1);
            text-align: center;
        }
        .debug-btn {
            width: auto;
            height: 0.8rem;
            text-align: center;
            line-height: 0.8rem;
            color: #ffffff;
            font-size: 0.3rem;
        }
    }

    .debug-work-status {
        position: absolute;
        top: 0.55rem;
        right: 4rem;
        color: #fff;
        font-size: 0.3rem;
        background: #24283e;
        padding: 0.3rem 0.3rem;
        border-radius: 0.1rem;
    }

    .debug-alert-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 998;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .debug-alert-content {
        width: auto;
        max-width: 50%;
        min-height: 2rem;
        background: #fff;
        padding: 0.3rem;
        border-radius: 0.1rem;
        position: relative;
        h3 {
            font-size: 0.3rem;
        }
        .debug-alert-close {
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            top: 0.2rem;
            right: 0.3rem;
            img {
                width: 100%;
            }
        }
        .debug-direction-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 0.3rem 0 0 0;
            span {
                width: 4rem;
                height: 1.5rem;
                display: inline-block;
                font-size: 0.3rem;
                padding: 0.3rem 0 0.3rem 0;
                display: flex;
                align-items: center;
            }
        }
        .debug-btn-wrap {
            width: 2.5rem;
            display: flex;
            justify-content: space-between;
            padding-top: 0.2rem;
        }

        .debug-option {
            font-size: 0.24rem;
            border: 1px solid #272b3e;
            color: #272b3e;
            padding: 0.15rem;
            border-radius: 0.1rem;
            margin-right: 0.15rem;
            margin-bottom: 0.15rem;
        }
        .active {
            .debug-option {
                background: #272b3e;
                color: #fff;
            }
        }
    }
    .rads-debug-info {
        font-size: 0.24rem;
        position: fixed;
        width: 13.5rem;
        background: #24283e;
        left: 4.5rem;
        color: #fff;
        .rads-row {
            display: flex;
            padding: 0 0.15rem;
            flex-wrap: wrap;
            .rads-row-item {
                padding: 0.3rem 0.15rem;
                flex-grow: 1;
                span {
                    padding-left: 0.05rem;
                }
            }
        }
        border-radius: 0.1rem;
    }
}

.group {
    padding: 0.18rem 0;
}
