.stop_sign_wrap {
  //position: absolute;
  //right: 2.7rem;
  //top: 2.4rem;
  //box-sizing: border-box;
  margin-bottom: 0.2rem;

  width: 0.8rem;
  height: 0.8rem;

 /* border: 3.5px solid #ff2828;
  background: #1C1D32;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;*/

  img{
    width: 100%;
    height: 100%;

  }

  /*span{
    color:#fff;
    font-weight: 600;
    font-size:.24rem;
  }*/
}
