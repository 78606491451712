.custom_elk_card_wrap {
    width: 6.06rem;
    height: 3.06rem;
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 0.42rem;
    padding: 0.36rem 0.45rem;
    margin-bottom: 0.3rem;

    .title_wrap {
        font-size: 0.32rem;
        font-weight: 600;
    }

    .sensitivity_wrap {
        width: 100%;
        margin-top: 0.5rem;

        .subtitle_wrap {
            font-weight: 500;
            font-size: 0.25rem;
            color: #495160;
            margin-bottom: 0.34rem;
        }
    }
}

.black .custom_fcw_card_wrap {
    background: #383d54;

    .title_wrap {
        color: #ffffff;
    }

    .sensitivity_wrap {
        .subtitle_wrap {
            color: #ffffff;
        }
    }
}

.custom_elk_not_active {
    opacity: 0.4;
}
