.toast-item {
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: .3rem;
    line-height: .5rem;
    color: #FFFFFF;
    padding: .20rem .4rem;
    max-width: 6.81rem;
    background: rgba(73, 81, 96, 0.6);
    border-radius: .08rem
}