.wrapper_normal {
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}

.main_normal {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    /*background: #FFFFFF;*/
}

.wrapper_err {
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}

.wrapper_err::before {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    height: 0.1rem;
    left: 1.8rem;
    border-radius: 50%;
    box-shadow: 0px -5px 10px 30px rgba(232, 56, 40, 0.5);
    filter: blur(50px);
}

.wrapper_err::after {
    content: '';
    display: block;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 1.8rem;
    height: 0.1rem;
    bottom: 0;
    border-radius: 50%;
    /*box-shadow: 0px 150px 10px 30px rgba(232, 56, 40, 0.5);*/
    box-shadow: 0px 5px 10px 30px rgba(232, 56, 40, 0.25);
    transform: rotate(180deg);
    filter: blur(50px);
}

.main_err {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    /*background: #FFFFFF;*/
}

.main_err::before {
    content: '';
    display: block;
    width: 2px;
    top: 17px;
    bottom: 25px;
    border-radius: 50%;
    box-shadow: 0px -5px 10px 30px rgba(232, 56, 40, 0.5);
    filter: blur(50px);
}

.main_err::after {
    content: '';
    display: block;
    position: absolute;
    top: 17px;
    bottom: 25px;
    right: 0;
    width: 2px;
    border-radius: 50%;
    box-shadow: 0px -5px 10px 30px rgba(232, 56, 40, 0.5);
    filter: blur(50px);
}

body {
    user-select: none;
}
