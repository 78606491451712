.pnp-style-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    .style-content {
        background: #24283e;
        box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.8);
        padding: 0.5rem 1.61rem;
        border-radius: 0.16rem;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.8rem;
        width: auto;
        flex-wrap: wrap;
        max-height: 8rem;
        min-height: 4rem;

        flex-direction: row;
        // writing-mode: vertical-lr;
        .lpnp-setting-close {
            position: absolute;
            top: 0rem;
            left: 0rem;
            padding: 0.32rem;
            > img {
                width: 0.4rem;
                height: 0.4rem;
            }
        }
        .sensitivity_wrap {
            width: 100%;
            margin-top: 0.3rem;

            .subtitle_wrap {
                font-weight: 500;
                font-size: 0.25rem;
                color: #fff;
                margin-bottom: 0.55rem;
            }
        }
        .custom_elk_not_active {
            opacity: 0.4;
        }
        .select_box {
            width: 100%;
            height: 0.8rem;
            line-height: 0.8rem;
            background: #e6e6e6;
            border-radius: 0.8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .select_box_item {
                color: #696f7b;
                height: 100%;
                width: 1.68rem;
                font-weight: 500;
                font-size: 0.25rem;
                display: flex;
                justify-content: center;
                &.active {
                    background: #ffffff;
                    box-shadow: 0 0.04rem 0.04rem rgba(71, 82, 101, 0.1);
                    border-radius: 0.8rem;
                }
            }
        }
    }
}
// .textarea-content {
//     p {
//         font-size: 0.25rem;
//         padding: 0.15rem;
//     }
//     .textarea {
//         height: 4rem;
//         background: #fff;
//         border: 0.01rem solid #f3f3f3;
//         border-radius: 0.1rem;
//         font-size: 0.25rem;
//         box-sizing: border-box;
//         overflow: auto;
//     }
// }
// .style-btn-group {
//     text-align: right;
//     margin-top: 0.5rem;
//     .cancel {
//         height: 0.7rem;
//         font-size: 0.24rem;
//         margin-right: 0.35rem;
//         width: 1.22rem;
//     }
//     .confirm {
//         height: 0.7rem;
//         width: 1.22rem;
//         font-size: 0.24rem;
//     }
// }
