.horizontal.adm-toast-mask .adm-toast-main {
}
/*变道condition*/
.unavailble .adm-toast-main {
    background-color: rgba(255, 200, 0, 0.85) !important;
}
.unavailble,
.availble {
    .condition-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .manual-text {
            //margin-left: 0.6rem;
            padding: 0 0.6rem 0 0.4rem;
        }
        .normal-text {
            //margin-left: 0.6rem;
            padding: 0 0.6rem 0 0.4rem;
        }
    }
    .adm-auto-center {
        justify-content: start;
    }
    img {
        margin-right: 0.14rem;
    }
}
.availble.done,
.unavailble.done {
    .adm-auto-center {
        justify-content: center;
    }
}
.vertical.adm-toast-mask .adm-toast-main {
    left: 50% !important;
    top: 35% !important;
}

//background  red
.toast-error .adm-toast-main {
    background: #ff0000 !important;
}
.toast-error {
    .condition-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .adm-auto-center {
        justify-content: start;
    }
    img {
        margin-right: 0.14rem;
    }
}
.left.horizontal.adm-toast-mask .adm-toast-main {
    left: 81% !important;
    top: 70% !important;
}
.right.horizontal.adm-toast-mask .adm-toast-main {
    left: 25% !important;
    top: 70% !important;
}
.adm-mask.adm-toast-mask {
    z-index: 998;
}
.custom_toast-subtitle {
    font-size: 70%;
    font-weight: normal;
}

.adm-mask.adm-toast-mask.parking {
    &.d .adm-toast-main {
        left: 50% !important;
        top: 80% !important;
    }
    &.sr .adm-toast-main {
        left: 50% !important;
        top: 85% !important;
    }
}
