.parking-tips {
    position: absolute;
    left: 0;
    right: 0;
    top: 1.7rem;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    height: 1.42rem;
    border-radius: 0.12rem;
    display: inline-block;
    display: flex;
    align-items: center;
    padding: 0 0.24rem;
    font-size: 0.38rem;
    color: #fff;
    .wrap {
        display: inline-flex;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        span {
            width: 0.63rem;
            height: 0.63rem;
            border-radius: 50%;
            margin-right: 0.22rem;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background: rgba(42, 105, 226, 1);
            img {
                width: 100%;
                height: 100%;
            }
        }
        p {
            font-weight: 500;
            // letter-spacing: 0.1rem;
        }
    }

    .parking-toast {
        font-size: 0.38rem;
        // letter-spacing: 0.1rem;
        font-weight: 500;
    }
}

.parking-tips.sr {
    p {
        color: #535861;
    }
}
