.road-test-setting {
    width: 100%;
    height: 100%;
    padding: 0 1.31rem 0 1.33rem;
    .road-test-setting-item {
        padding-top: .9rem;
        .road-test-setting-item-title {
            font-weight: 500;
            font-size: .29rem;
            line-height: .29rem;
            color: #495160;
        }
        .road-test-setting-item-content {
            margin-top: .44rem;
        }
    }
}
.road-test-setting.black, .Layout-box.black {
    .road-test-setting-item-title {
        color: #FFFFFF;
    }
}
