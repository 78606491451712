.navigation_tips_card_wrap {
  width: 6.06rem;
  height: 3.06rem;
  box-sizing: border-box;
  background: #F3F3F3;
  border-radius: .42rem;
  padding: .56rem .52rem;
  margin-bottom: .3rem;

  .title_wrap {
    font-size: .32rem;
    font-weight: 600;
    padding-top: 0.14rem;
    white-space: nowrap;
    color: #495160;
  }

  .sensitivity_wrap {
    width: 100%;
    margin-top: .61rem;
  }
}

.black .navigation_tips_card_wrap {
  background: #383D54;

  .title_wrap {
    color: #FFFFFF;
  }
}

