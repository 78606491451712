.parking-container {
    width: 100%;
    height: 100%;
    .top-bg{
        height: 4.68rem;
        width: 100%;
        position: fixed;
        top: 0;
        background: linear-gradient(180deg, rgba(244, 246, 246, 0.6) 0%, rgba(211, 226, 255, 0.6) 0.01%, rgba(244, 245, 246, 0) 97.12%);
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}