.custom_fcw_card_wrap {
  width: 6.06rem;
  height: 3.06rem;
  box-sizing: border-box;
  background: #F3F3F3;
  border-radius: .42rem;
  padding: .36rem .45rem;
  margin-bottom: .3rem;

  .title_wrap {
    font-size: .32rem;
    font-weight: 600;
  }

  .sensitivity_wrap {
    width: 100%;
    margin-top: .5rem;

    .subtitle_wrap {
      font-weight: 500;
      font-size: .25rem;
      color: #495160;
      margin-bottom: .34rem;
    }
  }
}


.black .custom_fcw_card_wrap {
  background: #383D54;

  .title_wrap {
    color: #FFFFFF;
  }

  .sensitivity_wrap {
    .subtitle_wrap {
      color: #FFFFFF;
    }
  }
}

.custom_fcw_not_active {
  opacity: 0.4;
}
