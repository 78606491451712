.right_info_wrap {
  position: absolute;
  //top:1.35rem;
  top: 1.64rem;
  right: 3.12rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
