.meb-wiget-wrapper {
    position: absolute;
    right: 2.73rem;
    top: 1.2rem;
    > img {
        width: 0.8rem;
        height: 0.8rem;
    }
    &.sr {
        right: auto;
        top: 3.04rem;
        left: 0.42rem;
    }
}
