.setting-modal-mask {
    width: calc(100% - 1.35rem);
    height: 100%;
    position: fixed;
    top: 0;
    right: 0 !important;
    background: rgba(00, 00, 00, 0.8);
    padding: 1.62rem 0.71rem 1.26rem 0.71rem;
    z-index: 1002;
    .setting-modal-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .setting-modal-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 0.19rem;
        overflow: hidden;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        .modal-box-left {
            width: 20%;
            height: 100%;

            .close-button-group {
                display: flex;
                align-items: center;
                color: #495160;
                .close {
                    padding: 0.61rem 0.25rem 0 0.6rem;
                    font-size: 0.23rem;
                }
                .modal-title {
                    padding: 0.61rem 0 0 0;
                    font-size: 0.32rem;
                    font-weight: 500;
                }
            }

            .setting-modal-tabs {
                padding-top: 0.76rem;
                .tab-item {
                    padding: 0.57rem 0 0.57rem 1.08rem;
                    font-weight: 500;
                    font-size: 0.29rem;
                    // display: flex;
                    // align-items: center;
                    color: #495160;
                }
                .active {
                    color: #2a69e2;
                }
            }
        }
        .modal-box-right {
            width: 80%;
            height: 100%;
            background-color: #e6e6e6;
            overflow-y: auto;

            .common-switch {
                margin-top: 0.54rem;
                --width: 0.95rem;
                --height: 0.57rem;
                .adm-switch-checkbox {
                    border-radius: 0.55rem;
                }
                .adm-switch-checkbox:before {
                    background: #c9c9c9;
                }
            }
            .select-group-switch {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 0.3rem;
                .custom-switch {
                    --width: 0.88rem;
                    --height: 0.5rem;
                    .adm-switch-checkbox {
                        border-radius: 0.55rem;
                    }
                    .adm-switch-checkbox:before {
                        background: #c9c9c9;
                    }
                }
                .switch-name {
                    font-weight: 500;
                    font-size: 0.28rem;
                    color: #495160;
                    padding-left: 0.2rem;
                }
            }
            .select-group-item {
                margin-top: 0.5rem;
                .select-group-item-name {
                    font-weight: 500;
                    font-size: 0.25rem;
                    line-height: 0.25rem;
                    padding-bottom: 0.24rem;
                }
                .auto-lanechange-tip {
                    color: #787e88;
                    font-size: 0.2rem;
                    line-height: 0.25rem;
                    padding-top: 0.2rem;
                }
                .select-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #e6e6e6;
                    border-radius: 0.8rem;
                    .select-box-item {
                        text-align: center;
                        flex: 1;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        font-size: 0.25rem;
                        font-weight: 500;
                        white-space: nowrap;
                        color: rgba(73, 81, 96, 0.7);
                    }
                    // .circle {
                    //     position: absolute;
                    //     top: 40%;
                    //     left: 10%;
                    //     transform: translate(-10%, -40%);
                    //     box-sizing: border-box;
                    //     width: 0.25rem;
                    //     height: 0.25rem;
                    //     border: 0.03rem solid #1e80ff;
                    //     border-radius: 50%;
                    //     border-top-color: transparent;
                    //     animation: rotate 1s linear infinite;
                    // }

                    // @keyframes rotate {
                    //     0% { transform: rotate(0deg); }
                    //     100% { transform: rotate(360deg); }
                    // }
                    .select-box-item.active {
                        background: #f9fafd;
                        box-shadow: 0 0.04rem 0.04rem rgba(71, 82, 101, 0.1);
                        border-radius: 0.8rem;
                        color: #495160;
                    }
                }
            }
        }
    }
}

.setting-modal-mask.black,
.Layout-box.black {
    .setting-modal-box {
        background: #383d54;
        .close-button-group {
            color: #ffffff;
        }
        .setting-modal-tabs {
            .tab-item {
                color: rgba(255, 255, 255, 0.4);
            }
            .active {
                color: #ffffff;
            }
        }
        .modal-box-right {
            background: #2e3247;
            .adm-switch-checked.custom-switch,
            .adm-switch-checked.common-switch {
                .adm-switch-handle {
                    background: #ffffff;
                }
                .adm-switch-checkbox,
                .adm-switch-checkbox:before {
                    background: #1677ff;
                }
            }
            .custom-switch,
            .common-switch {
                .adm-switch-handle {
                    background: #585d75;
                }
                .adm-switch-checkbox,
                .adm-switch-checkbox:before {
                    background: #2e3247;
                }
            }
            .select-group-switch {
                .switch-name {
                    color: #ffffff;
                }
            }
            .select-group-item {
                .select-box {
                    background: #2e3247;
                    .select-box-item {
                        color: rgba(255, 255, 255, 0.7);
                    }
                    .select-box-item.active {
                        background: #585d75;
                        box-shadow: 0px 4px 4px rgba(71, 82, 101, 0.1);
                        border-radius: 0.8rem;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
