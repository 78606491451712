.custom_ldw_card_wrap {
    width: 6.06rem;
    height: 6.4rem;
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 0.42rem;
    padding: 0.36rem 0.45rem;
    margin-bottom: 0.3rem;

    .title_wrap {
        font-size: 0.32rem;
        font-weight: 600;
    }

    .sensitivity_wrap,
    .ldw_warning_wrap {
        width: 95%;
        margin: 0.5rem auto;

        .subtitle_wrap {
            font-weight: 500;
            font-size: 0.25rem;
            color: #495160;
            margin-bottom: 0.24rem;
            white-space: nowrap;
        }
    }

    /*.switch_wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .35rem;

    .text_wrap {
      display: inline-block;
      font-weight: 500;
      font-size: .25rem;
      margin-left: .2rem;
    }
  }*/
}

.black .custom_ldw_card_wrap {
    background: #383d54;

    .title_wrap {
        color: #ffffff;
    }

    .sensitivity_wrap,
    .ldw_warning_wrap {
        .subtitle_wrap {
            color: #ffffff;
        }
    }

    /*.switch_wrap {
    .text_wrap {
      color: #FFFFFF;
    }
  }*/
}

.custom_ldw_not_active {
    opacity: 0.4;
}
