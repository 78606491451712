.hard_disk_wrap {
  z-index: 10;


  .img_wrap {
    width: .46rem;
  }

  .ring_element {
    box-sizing: border-box;
    width: .56rem;
    height: .56rem;
    background-color: #1a1b2f;
    border-radius: 50%;
    border: .08rem solid #290ae2;
  }
}
