.csw_wrap {
  //box-sizing: border-box;
  //margin-top: 0.2rem;
  margin-bottom: 0.2rem;

  width: 0.8rem;
  height: 0.8rem;

  img{
    width: 100%;
    height: 100%;
  }
}
