.driving_state {
    display: flex;
    color: #1cadff;
    font-size: 0.28rem;
    align-items: center;
    height: 1.2rem;
    .driving_state_left {
        padding: 0.2rem;
        background: #272b3e;
        border-radius: 0.2rem;
        img {
            width: 0.8rem;
            display: block;
            // height: .8rem;
        }
        .imgState:active {
            box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
            border-radius: 50%;
        }
    }
    .right_isshow {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .driving_state_right {
        height: 100%;
        line-height: 1.2rem;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        padding-right: 0.2rem;
        .right_text_state,
        .textState {
            word-break: keep-all;
            position: relative;
            white-space: nowrap;
        }
        .show_right_text {
            margin-left: 0.2rem;
            animation: text-slide-show 1s ease;
        }
        @keyframes text-slide-show {
            from {
                visibility: hidden;
                width: 5%;
            }
            10% {
                visibility: hidden;
                width: 20%;
            }
            to {
                visibility: visible;
                width: 100%;
            }
        }
        .right_text_state.show_right_text::before {
            content: '';
            position: absolute;
            display: block;
            left: -0.2rem;
            top: 50%;
            transform: translateY(-50%);
            height: 0.26rem;
            width: 0.02rem;
            background: #495160;
        }
        .right_text_state.hide_right_text {
            visibility: hidden;
        }
        .right_text_state:active {
            color: green;
            .enter_text {
                color: green;
            }
        }
        // p.show, .textState {
        //     word-break: keep-all;
        //     position: relative;
        //     white-space: nowrap;
        // }
        // p.show::before, .textState.show::before{
        //     content: '';
        //     position: absolute;
        //     display: block;
        //     left: -0.2rem;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     height: .26rem;
        //     width: .02rem;
        //     background: #495160;
        // }
        // p.hide, .textState.hide {
        //     visibility: hidden;
        // }
        // .textState:active{
        //     color: green;
        //     .enter_text{
        //         color: green;
        //     }
        // }
        // .active {
        //     color: #81869F;
        // }
    }
    .fail-right {
        padding: 0.2rem 0;
        padding-right: 0.2rem;
        .fail-state {
            line-height: 0.4rem;
            font-weight: 500;
            font-size: 0.28rem;
            color: #fff !important;
        }
        .fail-text {
            line-height: 0.4rem;
            font-weight: 300;
            font-size: 0.24rem;
            margin-top: 0.08rem;
            color: #fff !important;
        }
    }
    .show_right {
        animation: slideInLeft 500ms ease-out;
        background: #272b3e;
    }
    .hide-right {
        visibility: hidden;
    }
    @keyframes slideInLeft {
        from {
            width: 40%;
        }
        to {
            visibility: visible;
            width: 100%;
        }
    }
}
.driving_state_loading {
    padding: 0.2rem;
    background: #272b3e;
    border-radius: 0.2rem;
    align-items: center;
    .plus_item.loading,
    .plus_item.loading > div {
        position: relative;
        box-sizing: border-box;
    }
    .plus_item.loading {
        display: block;
        font-size: 0;
        color: rgba(255, 255, 255, 0.8);
    }
    .plus_item.loading > div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
    }
    .plus_item.loading {
        width: 0.8rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
    }

    .plus_item.loading > div:nth-child(1) {
        animation-delay: -200ms;
    }

    .plus_item.loading > div:nth-child(2) {
        animation-delay: -100ms;
    }

    .plus_item.loading > div:nth-child(3) {
        animation-delay: 0ms;
    }

    .plus_item.loading > div {
        width: 0.15rem;
        height: 0.15rem;
        margin: 0.05rem;
        border-radius: 100%;
        animation: ball-pulse 1s ease infinite;
    }
    @keyframes ball-pulse {
        0%,
        60%,
        100% {
            opacity: 1;
            transform: scale(1.1);
        }

        30% {
            opacity: 0.1;
            transform: scale(0.01);
        }
    }
}
.driving_toast {
    width: 4.1rem;
    height: 1.94rem;
    color: #fff;
    .toast_top {
        display: flex;
        align-items: center;
        padding: 0.28rem;
        background: #2a69e3;
        border-top-left-radius: 0.16rem;
        border-top-right-radius: 0.16rem;
        .top_img {
            width: 0.56rem;
            height: 0.56rem;
        }
        .top-title {
            margin-left: 0.18rem;
            font-weight: 500;
            font-size: 0.28rem;
        }
    }
    .toast_bottom {
        display: flex;
        align-items: center;
        background: #272b3e;
        justify-content: center;
        height: 0.81rem;
        border-bottom-left-radius: 0.16rem;
        border-bottom-right-radius: 0.16rem;
        .bottom_img {
            width: 0.88rem;
            height: 0.81rem;
        }
        .bottom-title {
            font-weight: 300;
            font-size: 0.24rem;
            // line-height: .37rem;
            margin-left: 0.1rem;
            max-width: 2.2rem;
        }
    }
}
.driving_odd_widget {
    .driving_state_right {
        height: 100%;
        padding: 0.2rem 0;
        padding-right: 0.2rem;
        position: relative;
        p {
            line-height: 0.4rem;
            word-break: keep-all;
            white-space: nowrap;
        }
        .reason_text {
            font-weight: 500;
            font-size: 0.28rem;
            color: #fff;
        }
        .sub_reason_text {
            font-weight: 300;
            font-size: 0.24rem;
            // margin-top: .08rem;
            color: #fff;
        }
        .enter_text {
            font-weight: 500;
            font-size: 0.28rem;
            color: #00a1f7;
            margin-top: 0.07rem;
        }
        .hide_right_text {
            visibility: hidden;
        }
        .enter_text:active {
            color: green;
            .enter_text {
                color: green;
            }
        }
    }
    .driving_state_right.show_right::before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        height: 0.26rem;
        width: 0.02rem;
        background: #495160;
    }
}
.driving_odd_widget.warningClass {
    .driving_state_left.right_isshow {
        box-shadow: inset 0.2rem 0 0.38rem rgba(42, 105, 226, 0.4);
    }
    .driving_state_right.show_right {
        box-shadow: inset -0.2rem 0 0.38rem rgba(42, 105, 226, 0.4);
    }
}

.driving_state.driving_level_widget {
    padding: 0.2rem;
    background: #272b3e;
    border-radius: 0.2rem;
    .plus_item {
        display: flex;
        align-items: center;
        p.show,
        .textState.show {
            margin-left: 0.2rem;
        }
        p.show::before,
        .textState.show::before {
            display: none;
        }
        .state_text {
            font-weight: 500;
            font-size: 0.28rem;
            color: #fff;
        }
        .sub_reason_text {
            font-weight: 300;
            font-size: 0.24rem;
            margin-top: 0.08rem;
            color: #fff;
        }
    }
}

.driving_state.driving_level_widget.hide {
    display: none;
}
