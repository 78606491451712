.right-content-img {
  .additional-btn {
    position: absolute;
    bottom: 0.2rem;
    right: 0.22rem;
  }
  .voice-times {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5866rem;
    color: #dbe1f6;
  }
}
.img-view-content {
  // position: relative;
  width: 16.2rem;
  height: 9.72rem;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //   background: url('~@/assets/workbench/default_loading.png') no-repeat center center / 100% 100%;

  > img {
    width: 100%;
    height: 100%;
  }

  .cricle-close {
    position: absolute;
    top: 0;
    right: -1.02rem;
    width: 0.73rem;
    height: 0.73rem;
    // background: #323339;
    // border-radius: 50%;
    // text-align: center;
    // .close-icon{
    //     display: inline-block;
    //     font-size: .30rem;
    //     line-height: .72rem;
    //     color: #a3a3a6;

    // }
  }
}
.workBench-modal-content {
  position: relative;
  width: 5.2rem;
  height: 2.2rem;
  // background: #FFFFFF;
  box-shadow: 0px 0.068rem 0.17rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.6296px);
  border-radius: 0.1rem;
  padding-left: 0.35rem;
  .title {
    height: 0.42rem;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 0.28rem;
    color: #495160;
    margin-top: 0.24rem;
  }
  .title-two {
    height: 0.25rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.2rem;
    line-height: 0.25rem;
    color: rgba(73, 81, 96, 0.8);
  }

  .cricle-outer {
    position: absolute;
    bottom: 0.29rem;
    left: 0.35rem;
    width: 0.45rem;
    height: 0.45rem;
    background-color: #2a69e2;
    border-radius: 50%;
    .cricle-inner {
      position: absolute;
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background-color: #ffffff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .cricle-inner.active {
      width: 0.19rem;
      height: 0.19rem;
      border-radius: 0.05rem;
    }
  }
  .cricle-outer:active {
    box-shadow: 0 0 0.2rem 0.1rem hsla(#1cadff, 100%, 41%, 0.5);
    border-radius: 50%;
  }
  .time-cutdown {
    position: absolute;
    bottom: 0.29rem;
    right: 0.25rem;
    .red-icon {
      display: inline-block;
      width: 0.15rem;
      height: 0.15rem;
      border-radius: 50%;
      background-color: #ff0000;
      margin-bottom: 0.015rem;
      margin-right: 0.15rem;
    }
    .cutdown-title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 0.28rem;
      line-height: 0.3rem;
      color: #495160;
    }
  }

  .close-icon {
    position: absolute;
    font-size: 0.24rem;
    top: 0.1rem;
    right: 0.15rem;
  }
}
