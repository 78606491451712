.widget-three-class{
  position: absolute;
  top: 4rem;
  z-index: 999;
  width: .77rem;
  border-radius: .16rem;
  //background: #1A1D30;
  background: #2F333C;
  overflow: hidden;
  padding: .05rem 0;
  .widget-three-class-wrap{
    width: .77rem;
    .text-box{
      width: .77rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        // margin-bottom: .22rem;
        padding: .1rem 0;
      }
      img.gifImg{
        //width: 100%;
        width: .44rem;
        overflow: hidden;
        padding-top:.1rem;
        padding-bottom: .12rem;
        //margin-bottom: .12rem;
      }
      .aeb {
        img {
          padding: 0;
        }
      }
      .aeb,.fcw{
        //width: .35rem;
        width: .44rem;
        align-items: center;
        img{
          width: 100%;
        }
      }
      .fcw{
        img{
          width: 120%;
        }
      }
    }
  }
  .widget-three-class-wrap:last-child{
    .text-box{
      img,img.gifImg{
        margin-bottom: 0;
      }
    }
  }
}
