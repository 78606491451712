.card_wrap {
    .customise-content {
        color: #495160;
        padding: 0.36rem 0.46rem;
        border-radius: 0.42rem;
        .customise-title,
        .switch-type {
            font-weight: 600;
            font-size: 0.32rem;
            line-height: 0.45rem;
            color: #495160;
        }
    }
    .disabled {
        opacity: 0.4;
    }
}

.black .card_wrap {
    .customise-content {
        color: #f3f3f3;
        padding: 0.36rem 0.46rem;
        border-radius: 0.42rem;
        .customise-title,
        .switch-type {
            font-weight: 600;
            font-size: 0.32rem;
            line-height: 0.45rem;
            color: #f3f3f3;
        }
    }
    .disabled {
        opacity: 0.4;
    }
}
