
.wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    flex-wrap: wrap;
    flex-direction: column;
}

.button{
    margin-right: .5rem;
    width: 2rem;
    height: 1rem;
    border-radius: 0.3rem;
    background: #eee;
    font-size: 0.2rem;
    text-align: center;
    line-height: 1rem;
}