.event-tag-container {
    padding: 0.15rem;
    .content {
        display: flex;
        gap: 0.3rem;
        h3 {
            font-weight: bold;
            font-size: 0.28rem;
            margin-bottom: 0.24rem;
        }
    }
    .event-info {
        width: 5.6rem;
        .report-detail {
            position: relative;
        }
    }
    .evnet-mark {
        width: 6.3rem;
        .check-group {
            width: 100%;
        }
        .mark-status {
            padding-top: 0.15rem;
            font-size: 0.24rem;
            time {
                padding-left: 0.1rem;
            }
        }
    }
    .adm-button {
        font-size: 0.26rem;
    }
    .btn-box {
        margin-top: 0.2rem;
        width: 100%;
    }
    .loading-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
    }
}
