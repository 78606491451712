.vcruise-contanier {
    width: .8rem;
    height: .8rem;
    margin-bottom: .2rem;
    .vcruise-element {
        width: 100%;
        height: .8rem;
        position: relative;
        .text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-weight: 700;
            font-size: .32rem;
            line-height: .8rem;
            color: #8991A0;
            text-align: center;
        }
    }
    .cruise-change {
        animation-name: heart-beat;
        animation-duration: 1.3s;
        animation-timing-function: ease;
    }
    .md-element {
        .text {
            color: #fff;
        }
    }
    img {
        width: 100%;
        height: 100%;
    }
}
@keyframes heart-beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
        font-size: .45rem;
        line-height: .88rem;
    }
    100% {
        transform: scale(1);
    }
}