.Layout-box {
    display: flex;
    /* position: absolute;
    left: 0;
    right: 0;*/
    width: 100%;
    height: 100%;
    // z-index: 999;
}
.sliderArea {
    // position: relative;
    width: 1.35rem;
    height: 100%;
    background: #090f19;
}
.sliderC {
    background: #e6e8f0;
}
.Layout-Container {
    display: flex;
    flex: 1;
    height: 100%;
    /*position: absolute;
    left: 1.35rem;
    right: 0;
    width: calc(100% - 1.35rem);
    height: 100%;*/
}
.mapArea {
    position: relative;
    height: 100%;
}

.mvizArea {
    position: relative;
    height: 100%;
}
.horizontal.Layout-Container {
}
.vertical.Layout-Container {
    flex-direction: column;
    .mapArea {
        width: 100% !important;
        height: 40% !important;
    }
    .mvizArea {
        width: 100% !important;
        height: 60% !important;
    }
}
.horizontal.Layout-Container.left {
    flex-direction: row-reverse;
}

.mapRightBox {
    position: absolute;
    right: 0.24rem;
    bottom: 0.24rem;
    &.show-record {
        bottom: 0.48 + 0.84rem;
    }
}
